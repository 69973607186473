import 'swiper/css';
import './style-module.css';

import { Box, Heading, Text } from '@chakra-ui/react';
import WhiteButton from 'src/components/white-button';
import { useGetBlogArticlesByCategoryQuery } from 'src/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CarouselCard } from './CarouselCard';
import { styles } from './styles';

export const Blog = () => {
  const { data: blogs } = useGetBlogArticlesByCategoryQuery();
  return (
    <Box sx={styles.blogScreenWrapper} id={'blog'} name={'blog'}>
      <Heading sx={styles.titleText}>ПОГОВОРИМ ПРО ФРИДАЙВИНГ?</Heading>
      <Text sx={styles.subtitleText}>
        Здесь будет собрана информация о технике, снаряжении, психологии и философии фридайвинга.
        Статьи написанные мной, без копипаста.
      </Text>
      <Box sx={styles.carouselWrapper}>
        <Swiper
          id={'blog-screen-swiper'}
          slidesPerView={'auto'}
          spaceBetween={32}
          freeMode={{
            enabled: true,
            minimumVelocity: 7,
          }}
          resistanceRatio={0}
        >
          {blogs?.getBlogArticlesByCategory?.new.map((article, index) => (
            <SwiperSlide key={index} id={'blog-screen-swiper-slide'}>
              <CarouselCard articleData={article} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box sx={styles.blogButtonWrapper}>
        <WhiteButton text={'Перейти в блог'} link={'/blog'} inBlog isBackShadow />
      </Box>
    </Box>
  );
};
