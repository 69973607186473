import { styles } from './styles';

const TextCard = ({ padding, width, height, fontSize, textWidth, text }) => {
  return (
    <div style={styles.textWrapper(padding, width, height)}>
      <span style={styles.text(fontSize, textWidth)}>{text}</span>
    </div>
  );
};

export default TextCard;
