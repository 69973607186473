const CloseIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 22 22'
    >
      <path
        fill='#2D2828'
        fillRule='evenodd'
        d='M22 19.168l-8.185-8.187L22 2.807 19.168 0l-8.182 8.179L2.81 0 0 2.81l8.186 8.196L0 19.19 2.81 22l8.203-8.192L19.193 22 22 19.168z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export default CloseIcon;
