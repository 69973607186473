const WinkingFaceIcon = () => (
  <svg
    height='100%'
    width='100%'
    fill='none'
    viewBox='0 0 22 22'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z'
      fill='url(#paint0_radial_29_97)'
    />
    <path
      d='M14.2275 15.7006C14.2452 15.6867 14.2668 15.6788 14.2892 15.678C14.3116 15.6771 14.3337 15.6834 14.3524 15.6959C14.371 15.7084 14.3852 15.7265 14.3929 15.7476C14.4006 15.7687 14.4015 15.7917 14.3954 15.8132C14.0168 17.2549 12.6444 18.3217 11.008 18.3217C9.37152 18.3217 7.99778 17.2527 7.62059 15.81C7.61459 15.7885 7.61555 15.7656 7.62333 15.7446C7.63111 15.7236 7.64531 15.7057 7.66391 15.6932C7.68251 15.6808 7.70456 15.6745 7.72692 15.6754C7.74928 15.6762 7.7708 15.6841 7.78843 15.6978C8.6021 16.3204 9.7446 16.7081 11.008 16.7081C12.2713 16.7081 13.4134 16.3218 14.2275 15.7006Z'
      fill='url(#paint4_radial_29_97)'
    />
    <path
      d='M14.2275 15.7006C14.2452 15.6867 14.2668 15.6788 14.2892 15.678C14.3116 15.6771 14.3337 15.6834 14.3524 15.6959C14.371 15.7084 14.3852 15.7265 14.3929 15.7476C14.4006 15.7687 14.4015 15.7917 14.3954 15.8132C14.0168 17.2549 12.6444 18.3217 11.008 18.3217C9.37152 18.3217 7.99778 17.2527 7.62059 15.81C7.61459 15.7885 7.61555 15.7656 7.62333 15.7446C7.63111 15.7236 7.64531 15.7057 7.66391 15.6932C7.68251 15.6808 7.70456 15.6745 7.72692 15.6754C7.74928 15.6762 7.7708 15.6841 7.78843 15.6978C8.6021 16.3204 9.7446 16.7081 11.008 16.7081C12.2713 16.7081 13.4134 16.3218 14.2275 15.7006Z'
      stroke='url(#paint5_linear_29_97)'
      strokeWidth='0.10884'
    />
    <path
      d='M3.45321 8.8014C3.61695 8.33664 4.70062 5.57 7.23056 5.68721C7.30969 5.68918 7.38577 5.7181 7.44623 5.76919C7.50669 5.82027 7.5479 5.89047 7.56305 5.96816C7.57855 6.06531 7.55256 6.17204 7.42348 6.26052C6.86659 6.64181 5.43812 6.63223 4.20987 8.48396C4.10484 8.64623 4.00802 8.81368 3.91979 8.98566C3.85503 9.10881 3.73735 9.27847 3.58821 9.24518C3.46142 9.2169 3.42676 9.10333 3.42128 9.00801C3.41792 8.93769 3.42878 8.86742 3.45321 8.8014Z'
      fill='#42210B'
    />
    <path
      d='M18.4472 9.75154C18.0764 9.42681 15.7745 7.54817 13.6272 8.89136C13.5588 8.93213 13.5065 8.99512 13.4789 9.06982C13.4513 9.14453 13.4502 9.22642 13.4758 9.30184C13.51 9.39306 13.5852 9.47424 13.7408 9.48792C14.4144 9.5463 15.6536 8.83845 17.6321 9.84824C17.8041 9.93626 17.9683 10.0389 18.1306 10.1433C18.2474 10.2186 18.4339 10.3084 18.547 10.2067C18.6437 10.1196 18.6182 10.0038 18.5762 9.91802C18.5449 9.85439 18.501 9.79776 18.4472 9.75154Z'
      fill='#42210B'
    />
    <path
      d='M13.4936 12.8001C13.2801 12.9725 13.5392 12.7768 13.6085 12.7381C13.7696 12.6468 13.9422 12.5773 14.1216 12.5315C14.8094 12.349 15.4753 12.5456 16.075 12.8941C16.746 13.2836 17.3521 12.2446 16.6821 11.8555C15.4333 11.1299 13.8165 11.0004 12.643 11.9495C12.0409 12.4361 12.897 13.2827 13.4936 12.8001Z'
      fill='url(#paint6_radial_29_97)'
    />
    <path
      d='M7.67023 13.6841C8.44202 13.6841 9.06769 12.7456 9.06769 11.5879C9.06769 10.4302 8.44202 9.4917 7.67023 9.4917C6.89843 9.4917 6.27277 10.4302 6.27277 11.5879C6.27277 12.7456 6.89843 13.6841 7.67023 13.6841Z'
      fill='url(#paint7_radial_29_97)'
    />
    <path
      d='M7.67023 13.6841C8.44202 13.6841 9.06769 12.7456 9.06769 11.5879C9.06769 10.4302 8.44202 9.4917 7.67023 9.4917C6.89843 9.4917 6.27277 10.4302 6.27277 11.5879C6.27277 12.7456 6.89843 13.6841 7.67023 13.6841Z'
      stroke='url(#paint8_linear_29_97)'
      strokeWidth='0.0870719'
    />
    <path
      d='M13.4936 12.8001C13.2801 12.9725 13.5392 12.7768 13.6085 12.7381C13.7696 12.6468 13.9422 12.5773 14.1216 12.5315C14.8094 12.349 15.4753 12.5456 16.075 12.8941C16.746 13.2836 17.3521 12.2446 16.6821 11.8555C15.4333 11.1299 13.8165 11.0004 12.643 11.9495C12.0409 12.4361 12.897 13.2827 13.4936 12.8001V12.8001Z'
      stroke='url(#paint9_linear_29_97)'
      strokeWidth='0.0870719'
    />
    <g
      style={{
        mixBlendMode: 'soft-light',
      }}
    >
      <path
        d='M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z'
        fill='url(#paint1_radial_29_97)'
      />
    </g>
    <g
      style={{
        mixBlendMode: 'soft-light',
      }}
    >
      <path
        d='M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z'
        fill='url(#paint2_radial_29_97)'
      />
    </g>
    <g
      style={{
        mixBlendMode: 'overlay',
      }}
    >
      <path
        d='M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z'
        fill='url(#paint3_radial_29_97)'
        fillOpacity='0.7'
      />
    </g>
    <g mask='url(#mask0_29_97)'>
      <g>
        <path
          d='M-0.06277 10.686C-0.0508257 10.5297 0.000608519 10.5141 0.0156102 10.6728C0.0322774 10.8491 -0.000122003 11.2036 0.00767833 11.4174C0.0283321 11.9836 0.11512 12.4758 0.204588 12.8763C0.492903 14.1666 0.892174 14.9981 1.26458 15.7026C2.4887 18.0182 3.82963 19.1908 5.13774 20.0746C7.05522 21.37 8.98828 21.782 10.9229 21.8694C10.9717 21.8716 10.9798 22.1895 10.9318 22.2309C10.8453 22.3055 10.738 22.2315 10.6494 22.2283C10.395 22.2194 10.1405 22.202 9.88602 22.1755C9.1315 22.0971 8.37703 21.9374 7.62407 21.6805C5.57681 20.9821 3.36942 19.786 1.44165 16.4174C1.32038 16.2055 1.20007 15.9824 1.08146 15.7426C0.986602 15.5509 0.89267 15.3499 0.800392 15.135C0.496875 14.4279 -0.210422 12.618 -0.06277 10.686ZM10.9243 22.2343C10.8777 22.2357 10.8659 21.9394 10.9103 21.8779C10.9774 21.7849 11.0779 21.8615 11.1486 21.8571C11.4423 21.8387 11.7359 21.8115 12.0294 21.7746C12.8342 21.6736 13.6384 21.501 14.4408 21.2353C16.431 20.5762 18.5706 19.5714 20.4468 16.4844C20.5662 16.2879 20.6845 16.0795 20.8013 15.8555C21.1562 15.1746 22.0457 13.3672 21.9428 11.1203C21.9342 10.9309 21.9918 10.8175 22.0176 10.98C22.0505 11.1868 22.0372 11.6182 22.0281 11.8477C21.9991 12.5827 21.8862 13.2042 21.7672 13.7062C21.39 15.2971 20.8605 16.2195 20.3756 16.9897C18.7993 19.4931 17.0874 20.5023 15.4229 21.2275C13.9301 21.8779 12.4259 22.188 10.9243 22.2343Z'
          fill='#401C02'
        />
      </g>
      <g>
        <path
          d='M-0.06277 10.686C-0.0508257 10.5297 0.000608519 10.5141 0.0156102 10.6728C0.0322774 10.8491 -0.000122003 11.2036 0.00767833 11.4174C0.0283321 11.9836 0.11512 12.4758 0.204588 12.8763C0.492903 14.1666 0.892174 14.9981 1.26458 15.7026C2.4887 18.0182 3.82963 19.1908 5.13774 20.0746C7.05522 21.37 8.98828 21.782 10.9229 21.8694C10.9717 21.8716 10.9798 22.1895 10.9318 22.2309C10.8453 22.3055 10.738 22.2315 10.6494 22.2283C10.395 22.2194 10.1405 22.202 9.88602 22.1755C9.1315 22.0971 8.37703 21.9374 7.62407 21.6805C5.57681 20.9821 3.36942 19.786 1.44165 16.4174C1.32038 16.2055 1.20007 15.9824 1.08146 15.7426C0.986602 15.5509 0.89267 15.3499 0.800392 15.135C0.496875 14.4279 -0.210422 12.618 -0.06277 10.686ZM10.9243 22.2343C10.8777 22.2357 10.8659 21.9394 10.9103 21.8779C10.9774 21.7849 11.0779 21.8615 11.1486 21.8571C11.4423 21.8387 11.7359 21.8115 12.0294 21.7746C12.8342 21.6736 13.6384 21.501 14.4408 21.2353C16.431 20.5762 18.5706 19.5714 20.4468 16.4844C20.5662 16.2879 20.6845 16.0795 20.8013 15.8555C21.1562 15.1746 22.0457 13.3672 21.9428 11.1203C21.9342 10.9309 21.9918 10.8175 22.0176 10.98C22.0505 11.1868 22.0372 11.6182 22.0281 11.8477C21.9991 12.5827 21.8862 13.2042 21.7672 13.7062C21.39 15.2971 20.8605 16.2195 20.3756 16.9897C18.7993 19.4931 17.0874 20.5023 15.4229 21.2275C13.9301 21.8779 12.4259 22.188 10.9243 22.2343Z'
          fill='#401C02'
        />
      </g>
    </g>
    <mask
      height='22'
      id='mask0_29_97'
      style={{
        maskType: 'alpha',
      }}
      width='22'
      x='0'
      y='0'
      maskUnits='userSpaceOnUse'
    >
      <path
        d='M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z'
        fill='white'
      />
    </mask>
    <defs>
      <radialGradient
        id='paint0_radial_29_97'
        cx='0'
        cy='0'
        gradientTransform='translate(10.9918) rotate(90) scale(21.9836)'
        gradientUnits='userSpaceOnUse'
        r='1'
      >
        <stop stopColor='#FFDE43' />
        <stop offset='1' stopColor='#FFBC00' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_29_97'
        cx='0'
        cy='0'
        gradientTransform='translate(10.9918 2.94179) rotate(90) scale(19.0418)'
        gradientUnits='userSpaceOnUse'
        r='1'
      >
        <stop stopColor='white' stopOpacity='0.5' />
        <stop offset='0.782278' />
      </radialGradient>
      <radialGradient
        id='paint2_radial_29_97'
        cx='0'
        cy='0'
        gradientTransform='translate(10.9918 2.94179) rotate(90) scale(19.0418)'
        gradientUnits='userSpaceOnUse'
        r='1'
      >
        <stop stopColor='white' stopOpacity='0.5' />
        <stop offset='0.782278' />
      </radialGradient>
      <radialGradient
        id='paint3_radial_29_97'
        cx='0'
        cy='0'
        gradientTransform='translate(10.9918 -2.39448) rotate(90) scale(24.3781 32.9678)'
        gradientUnits='userSpaceOnUse'
        r='1'
      >
        <stop offset='0.0922635' stopColor='white' />
        <stop offset='0.670207' stopColor='white' stopOpacity='0' />
      </radialGradient>
      <radialGradient
        id='paint4_radial_29_97'
        cx='0'
        cy='0'
        gradientTransform='translate(11.0081 16.9932) scale(2.55988 2.67306)'
        gradientUnits='userSpaceOnUse'
        r='1'
      >
        <stop stopColor='#743C1C' />
        <stop offset='1' stopColor='#401C02' />
      </radialGradient>
      <radialGradient
        id='paint6_radial_29_97'
        cx='0'
        cy='0'
        gradientTransform='translate(14.7024 12.1266) scale(2.89095 0.854258)'
        gradientUnits='userSpaceOnUse'
        r='1'
      >
        <stop stopColor='#743C1C' />
        <stop offset='1' stopColor='#401C02' />
      </radialGradient>
      <radialGradient
        id='paint7_radial_29_97'
        cx='0'
        cy='0'
        gradientTransform='translate(7.66829 11.5865) scale(1.78142 2.09983)'
        gradientUnits='userSpaceOnUse'
        r='1'
      >
        <stop stopColor='#743C1C' />
        <stop offset='1' stopColor='#401C02' />
      </radialGradient>
      <linearGradient
        id='paint5_linear_29_97'
        gradientUnits='userSpaceOnUse'
        x1='11.0081'
        x2='11.0081'
        y1='18.3962'
        y2='15.5901'
      >
        <stop offset='0.265625' stopColor='#FFDE43' />
        <stop offset='1' stopColor='#F79D14' />
      </linearGradient>
      <linearGradient
        id='paint8_linear_29_97'
        gradientUnits='userSpaceOnUse'
        x1='7.67023'
        x2='7.67023'
        y1='13.8021'
        y2='9.35679'
      >
        <stop stopColor='#FFDE43' />
        <stop offset='0.22' stopColor='#FED93F' />
        <stop offset='0.51' stopColor='#FCC934' />
        <stop offset='0.82' stopColor='#F9AF21' />
        <stop offset='1' stopColor='#F79D14' />
      </linearGradient>
      <linearGradient
        id='paint9_linear_29_97'
        gradientUnits='userSpaceOnUse'
        x1='14.7056'
        x2='14.7056'
        y1='13.028'
        y2='11.2195'
      >
        <stop stopColor='#FFDE43' />
        <stop offset='0.22' stopColor='#FED93F' />
        <stop offset='0.51' stopColor='#FCC934' />
        <stop offset='0.82' stopColor='#F9AF21' />
        <stop offset='1' stopColor='#F79D14' />
      </linearGradient>
    </defs>
  </svg>
);

export default WinkingFaceIcon;
