const ArrowDownIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 30 15'
    >
      <path
        fill='#183F55'
        d='M15.174 14.991c.35-.037.68-.18.948-.413L29.378 3.153a1.81 1.81 0 00.595-1.679A1.775 1.775 0 0028.85.107a1.713 1.713 0 00-1.718.312L15 10.874 2.867.42A1.718 1.718 0 00.41.637a1.813 1.813 0 00.213 2.516l13.255 11.425c.36.312.829.46 1.298.413z'
      ></path>
    </svg>
  );
};

export default ArrowDownIcon;
