const ArrowUpIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 12'
    >
      <path
        fill='#2D2828'
        d='M11.86.007c-.279.03-.543.144-.758.33L.498 9.477c-.382.33-.564.841-.477 1.344.088.502.43.92.899 1.093.469.174.992.08 1.374-.25L12 3.302l9.706 8.364a1.375 1.375 0 001.967-.174 1.45 1.45 0 00-.17-2.013L12.898.338A1.374 1.374 0 0011.86.006z'
      ></path>
    </svg>
  );
};

export default ArrowUpIcon;
