import InstagramIcon from 'src/assets/icons/InstagramIcon';
import PhoneIcon from 'src/assets/icons/PhoneIcon';
import TelegramIcon from 'src/assets/icons/TelegramIcon';
import VkIcon from 'src/assets/icons/VkIcon';
import YouTubeIcon from 'src/assets/icons/YouTubeIcon';

export const IS_3XL_WIDTH = '(min-width: 1920px)';
export const IS_2XL_WIDTH = '(min-width: 1536px)';
export const IS_XL_WIDTH = '(min-width: 1280px)';
export const IS_LG_WIDTH = '(min-width: 992px)';
export const IS_MD_WIDTH = '(min-width: 768px)';
export const IS_SM_WIDTH = '(min-width: 480px)';

export const PAGE_ROUTES = [
  {
    id: 'freediving-and-me',
    text: 'Фридайвинг и я',
  },
  {
    id: 'my-travels',
    text: 'Мои путешествия',
  },
  {
    id: 'tours',
    text: 'Туры',
  },
  {
    id: 'reviews',
    text: 'Отзывы',
  },
  {
    id: 'blog',
    text: 'Блог',
  },
  {
    id: 'contacts',
    text: 'Контакты',
  },
];

export const SOCIAL_MEDIA = [
  {
    id: 1,
    link: 'https://youtube.com/c/ViktorZvorykin',
    icon: <YouTubeIcon />,
    text: 'youtube',
  },
  {
    id: 2,
    link: 'https://t.me/FinKoBapHbIu',
    icon: <TelegramIcon />,
    text: 'telegram',
  },
  {
    id: 3,
    link: 'https://www.instagram.com/freediving.space/',
    icon: <InstagramIcon />,
    text: 'instagram',
  },
  {
    id: 4,
    link: 'https://m.vk.com/zvorikin',
    icon: <VkIcon size={'100%'} />,
    text: 'вконтакте',
  },
  {
    id: 5,
    link: 'https://www.google.com/',
    icon: <PhoneIcon />,
    text: 'телефон',
  },
];
