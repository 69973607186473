import './style-module.css';

// eslint-disable-next-line object-curly-newline
import { Box, Heading, Image, Spinner, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import parse from 'html-react-parser';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import NavLeftArrowIcon from 'src/assets/icons/NavLeftArrowIcon';
import Footer from 'src/components/footer';
import MenuDrawer from 'src/components/menu-drawer';
import { IS_XL_WIDTH } from 'src/constants';
import { useGetBlogArticleQuery } from 'src/hooks';
import { scrollToTop } from 'src/utils';

import { styles } from './styles';

const BlogArticle = () => {
  const [isXlWidth] = useMediaQuery(IS_XL_WIDTH);
  const { id } = useParams();
  const { pathname } = useLocation();

  React.useEffect(() => {
    scrollToTop();
  }, [pathname]);

  const { data, loading } = useGetBlogArticleQuery({
    variables: {
      id,
    },
  });

  return (
    <>
      {' '}
      <Box sx={styles.blogArticlePageWrapper}>
        {!loading ? (
          <>
            <Box sx={styles.headerBlock}>
              <Link to='/blog'>
                <Box sx={styles.navBlock}>
                  <Box sx={styles.iconWrapper}>
                    <NavLeftArrowIcon />
                  </Box>
                  <Text sx={styles.navText}>Назад в блог </Text>
                </Box>
              </Link>
              {isXlWidth ? null : <MenuDrawer iconColour={'#2D2828'} />}
            </Box>
            <Box sx={styles.articleContent}>
              <Image
                loading='lazy'
                src={data?.getBlogArticle.photoUrl}
                alt='titleImage'
                sx={styles.titleImage}
              />
              <Heading sx={styles.articleTitle}>{data?.getBlogArticle.title}</Heading>
              <Stack spacing='20px' className='blog-article-content'>
                {parse(data?.getBlogArticle?.content)}
              </Stack>
              <Box sx={styles.bottomMargin} />
            </Box>
          </>
        ) : (
          <Spinner
            thickness='0.7vw'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.200'
            sx={styles.loadingSpinner}
          />
        )}
      </Box>
      {!loading ? <Footer /> : null}
    </>
  );
};

export default BlogArticle;
