/* eslint-disable no-console */
import { onError } from '@apollo/client/link/error';

export const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (process.env.NODE_ENV !== 'production') {
    if (graphQLErrors) {
      console.log('graphQLError:', graphQLErrors);
    }
    if (networkError) {
      console.log('networkError:', networkError);
    }
  }
});
