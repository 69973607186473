import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

import { errorLink } from './errorLink';
import { httpLink } from './httpLink';

export const paginationMerge = (key, keyArguments = false) => {
  return {
    [key]: {
      keyArgs: keyArguments,
      merge(existing, incoming) {
        const previousEdges = existing?.reviews || [];
        const incomingEdges = incoming?.reviews || [];

        return {
          ...incoming,
          reviews: [...previousEdges, ...incomingEdges],
        };
      },
    },
  };
};

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          ...paginationMerge('getReviewsWithCount'),
        },
      },
    },
  }),
});
