import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';

import Layout from './components/layout';
import { client } from './graphql/client';
import Router from './router';
import theme from './theme';

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ChakraProvider
        theme={extendTheme({
          ...theme,
        })}
      >
        <BrowserRouter>
          <Layout>
            <Router />
          </Layout>
        </BrowserRouter>
      </ChakraProvider>
    </ApolloProvider>
  );
};

export default App;
