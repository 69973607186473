import { styles } from './styles';

const ScreenBackground = ({ width, height, src, alt, mt, position }) => {
  return (
    <div style={styles.imageWrapper(width, height, mt, position)}>
      <img src={src} alt={alt} style={styles.image} />
    </div>
  );
};

export default ScreenBackground;
