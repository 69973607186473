import { gql } from '@apollo/client';

import { PARTIAL_BLOG_ARTICLE } from '../fragments';

export const GET_BLOG_ARTICLES_BY_CATEGORY = gql`
  ${PARTIAL_BLOG_ARTICLE}
  query getBlogArticlesByCategory {
    getBlogArticlesByCategory {
      new {
        ...PartialFragmentArticle
      }
      forBeginners {
        ...PartialFragmentArticle
      }
      myTravels {
        ...PartialFragmentArticle
      }
    }
  }
`;
