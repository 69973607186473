import 'swiper/css';
import 'swiper/css/navigation';
import './style-module.css';

// Прогони у себя через линтер, пожалуйста. У меня не хочет эту строчку фиксить.
// eslint-disable-next-line object-curly-newline
import {
  Box,
  Image,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  Wrap,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import { DateTime as dt } from 'luxon';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import NavLeftArrowIcon from 'src/assets/icons/NavLeftArrowIcon';
import Footer from 'src/components/footer';
import MenuDrawer from 'src/components/menu-drawer';
import { IS_MD_WIDTH, IS_XL_WIDTH } from 'src/constants';
import { useGetTourQuery } from 'src/hooks';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import GalleryModal from '../GalleryModal';
import { InfoLabel } from './InfoLabel';
import { styles } from './styles';
import { TripModal } from './TripModal';

const TripArticle = () => {
  const [isXlWidth] = useMediaQuery(IS_XL_WIDTH);
  const [isMdWidth] = useMediaQuery(IS_MD_WIDTH);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentModalContent, setCurrentModalContent] = React.useState([]);
  const { id } = useParams();
  const { data: tour, loading } = useGetTourQuery({
    variables: {
      id,
    },
  });

  const imageProcessing = () => {
    const iterableContent = document.querySelectorAll('.day-content-wrapper');
    // eslint-disable-next-line no-restricted-syntax
    for (const content of iterableContent) {
      const images = content.querySelectorAll('img');

      [...images]?.map((image) => {
        if (
          image.nextSibling?.nodeName !== 'IMG' &&
          image.previousSibling?.nodeName !== 'IMG' &&
          image.classList?.value !== 'wrapped-image'
        ) {
          image.classList.add('solo-image');
        }
        if (
          image.nextSibling?.nodeName !== 'IMG' &&
          image.previousSibling?.nodeName === 'IMG' &&
          content?.querySelectorAll('.wrapped-image').length < 3
        ) {
          image.classList.add('wrapped-image');
        }
        if (
          image.nextSibling?.nodeName === 'IMG' &&
          image.previousSibling?.nodeName === 'IMG' &&
          content?.querySelectorAll('.wrapped-image').length < 3
        ) {
          image.classList.add('wrapped-image');
        }
        if (
          image.nextSibling?.nodeName === 'IMG' &&
          image.previousSibling?.nodeName !== 'IMG' &&
          content?.querySelectorAll('.wrapped-image').length < 3
        ) {
          image.classList.add('wrapped-image');
          image.before(document.createElement('div'));
        }
        content?.querySelector('div')?.classList.add('image-wrapper');
        // eslint-disable-next-line no-unsafe-optional-chaining
        [...content?.querySelectorAll('.wrapped-image')]?.map((img) => {
          content?.querySelector('.image-wrapper')?.append(img);
          content?.querySelector('div:not(.image-wrapper)')?.remove('div');
          img.addEventListener('click', () => setCurrentModalContent(img));
          img.addEventListener('click', () => onOpen());
          return null;
        });
        // eslint-disable-next-line no-unsafe-optional-chaining
        [...content?.querySelectorAll('.solo-image')]?.map((img) => {
          if (img.classList.value === 'solo-image' && img.previousSibling?.nodeName === 'DIV') {
            img.classList.remove('solo-image');
            content?.querySelector('.image-wrapper')?.append(img);
          }
          img.addEventListener('click', () => setCurrentModalContent(img));
          img.addEventListener('click', () => onOpen());
          return null;
        });
        // eslint-disable-next-line unicorn/no-array-for-each
        content?.querySelector('.image-wrapper')?.childNodes?.forEach((element) => {
          element?.classList.remove('wrapped-image');
          element?.classList.add('wrapped-image');
        });
        return null;
      });
    }
  };
  React.useLayoutEffect(() => {
    imageProcessing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const renderSlide = (slideData) => {
    return (
      <Box>
        <Box sx={styles.slideWrapper}>
          <Text sx={styles.slideTitle}>{tour?.getTour.name}</Text>
          <Image loading='lazy' src={slideData} alt='slideImage' sx={styles.slideImage} />
          {isMdWidth ? (
            <Box sx={styles.imageDescription}>
              <Text sx={styles.imageDescriptionText}>
                {tour?.getTour.emotionsThatPeopleExperience}
              </Text>
            </Box>
          ) : null}
          {!isMdWidth ? (
            <Box sx={styles.mobileImageDescription}>
              <Text sx={styles.mobileImageDescriptionText}>
                {tour?.getTour.emotionsThatPeopleExperience}
              </Text>
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={styles.tripArticlePageWrapper}>
      {!loading ? (
        <>
          <GalleryModal
            isOpen={isOpen}
            onClose={onClose}
            currentModalContent={currentModalContent}
          />
          <Box sx={styles.headerBlock}>
            <Link to='/'>
              <Box sx={styles.navBlock}>
                <Box sx={styles.iconWrapper}>
                  <NavLeftArrowIcon color={'#f1f1f1'} />
                </Box>
                <Text sx={styles.navText}>Назад </Text>
              </Box>
            </Link>
            {isXlWidth ? null : <MenuDrawer iconColour={'#F1F1F1'} />}
          </Box>
          <Box sx={styles.sliderContainer}>
            <Swiper
              navigation={true}
              modules={[EffectFade, Navigation]}
              effect={'fade'}
              id={'trip-page-swiper'}
            >
              {tour?.getTour.urlPhotos.map((item, index) => (
                <SwiperSlide id={'trip-page-swiper-slide'} key={index}>
                  {renderSlide(item)}
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <Box sx={styles.tripInfoContainer}>
            <Text sx={styles.aboutTitle}>О поездке</Text>
            <Wrap sx={styles.labelsWrapper} spacing='8px'>
              <InfoLabel
                labelTitle='Даты'
                labelText={`с ${dt
                  .fromMillis(Number.parseInt(tour?.getTour.date.from, 10))
                  .toFormat('dd.MM.yyyy')} по ${dt
                  .fromMillis(Number.parseInt(tour?.getTour.date.to, 10))
                  .toFormat('dd.MM.yyyy')}`}
              />
              <InfoLabel labelTitle='Уровень сложности' labelText={tour?.getTour.difficultyLevel} />
              <InfoLabel
                labelTitle='Температура водуха'
                labelText={`от ${tour?.getTour.airTemperature.from}° до  ${tour?.getTour.airTemperature.to}°`}
              />
              {tour?.getTour?.waterTemperature.from && tour.getTour?.waterTemperature.to ? (
                <InfoLabel
                  labelTitle='Температура воды'
                  labelText={`от ${tour?.getTour.waterTemperature.from}° до  ${tour?.getTour.waterTemperature.to}°`}
                />
              ) : null}
              <InfoLabel labelTitle='Тип проживания' labelText={tour?.getTour.typeOfResidence} />
              <InfoLabel
                labelTitle='Можно с детьми'
                labelText={tour?.getTour.isPossibleWithChildren ? 'Да' : 'Нет'}
              />
              <InfoLabel
                labelTitle='Стоимость'
                labelText={`${tour?.getTour.price.value} ${tour?.getTour.price.currency}/чел.`}
              />
              <InfoLabel
                labelTitle='Людей в группе'
                labelText={tour?.getTour.totalNumberOfPlacesInGroup}
              />
              <InfoLabel
                labelTitle='Свободные места'
                labelText={
                  tour?.getTour?.totalNumberOfPlacesInGroup >
                  tour?.getTour?.numberOfOccupiedPlacesInGroup
                    ? Number.parseInt(tour?.getTour.totalNumberOfPlacesInGroup, 10) -
                      Number.parseInt(tour?.getTour.numberOfOccupiedPlacesInGroup, 10)
                    : 'Нет'
                }
              />
            </Wrap>
            {tour?.getTour.additionalInfo ? (
              <Text sx={styles.additionalInfo}>{tour?.getTour.additionalInfo}</Text>
            ) : null}
            <Box>
              <Box sx={styles.daysContent}>
                {tour?.getTour?.days.map((day, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                    key={index + 1}
                  >
                    <Box sx={styles.dot} />
                    <Box sx={styles.dayContentWrapper}>
                      <Text sx={styles.dayTitle}>
                        {tour?.getTour.days[index].dayName
                          ? tour?.getTour.days[index].dayName
                          : `День ${index + 1}:`}
                      </Text>
                      <Stack spacing={'12px'} className={'day-content-wrapper'} sx={styles.stack}>
                        {parse(day.dayContent)}
                      </Stack>
                      {tour?.getTour?.days.length > index + 1 ? <Box sx={styles.line} /> : null}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <TripModal />
          </Box>
          <Footer />
        </>
      ) : (
        <Spinner
          thickness='0.7vw'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.200'
          sx={styles.loadingSpinner}
        />
      )}
    </Box>
  );
};

export default TripArticle;
