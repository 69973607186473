const NavLeftArrowIcon = ({ color = '#2D2828' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 20'
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M12.328 17.294l-5.233-5.38H24V8.087H7.259l5.234-5.38L9.861 0 0 10.033 9.696 20l2.632-2.706z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export default NavLeftArrowIcon;
