import 'swiper/css';
import './style-module.css';

import { Box, Heading, Text } from '@chakra-ui/react';
import WinkingFaceIcon from 'src/assets/icons/WinkingFaceIcon';
import WhiteButton from 'src/components/white-button';
import { useGetUpcomingToursQuery, useIntersectionObserver } from 'src/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CarouselCard } from './CarouselCard';
import { styles } from './styles';

export const Tours = () => {
  const { node, intersecting } = useIntersectionObserver();

  const { data: tours, loading } = useGetUpcomingToursQuery();
  return (
    <Box sx={styles.toursScreenWrapper} id={'tours'} name={'tours'}>
      {!loading ? (
        <>
          <Heading sx={styles.titleText}>КУДА МОЖНО ПОЕХАТЬ СО МНОЙ?</Heading>
          <Text sx={styles.subtitleText}>
            Вариативность локаций и их сложность, ближайшие туры подводные горные и наземные выбирай
            тут!
          </Text>
          <Box
            ref={node}
            className={intersecting === 'intersecting' ? 'play-animation' : ''}
            sx={styles.swiperContainer}
          >
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={32}
              freeMode={{
                enabled: true,
                minimumVelocity: 7,
              }}
              resistanceRatio={0}
              id={'tour-swiper'}
            >
              {tours?.getTours.upcomingTours?.map((tour, index) => (
                <SwiperSlide key={index} id={'tour-slide'}>
                  <CarouselCard tourInfo={tour} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <Box sx={styles.contactButton}>
            <WhiteButton
              link={'#contacts'}
              text='Нажми, если не знаешь, куда поехать'
              isInternetLink
              isBackShadow
            />
          </Box>
          <Box sx={styles.tipWrapper}>
            <Text sx={styles.tipText}>Я подскажу</Text>
            <Box sx={styles.iconWrapper}>
              <WinkingFaceIcon />
            </Box>
          </Box>
        </>
      ) : null}
    </Box>
  );
};
