import { Box, HStack, Text, useMediaQuery } from '@chakra-ui/react';
import { Link } from 'react-scroll';
import { IS_XL_WIDTH, PAGE_ROUTES } from 'src/constants';

import logo from '../../assets/images/logo.svg';
import MenuDrawer from '../menu-drawer';
import { styles } from './styles';

const Header = () => {
  const [isXlWidth] = useMediaQuery(IS_XL_WIDTH);
  return (
    <Box sx={styles.headerWrapper} id={'home'}>
      {/* <Heading sx={styles.logoText}>freediving.space</Heading> */}
      <img src={logo} alt='logo' style={styles.logo} />
      {isXlWidth ? (
        <HStack spacing={'2.222vw'}>
          {PAGE_ROUTES.map((route) => (
            <Link to={route.id} key={route.id} duration={1000}>
              <Text sx={styles.routeText}>{route.text}</Text>
            </Link>
          ))}
        </HStack>
      ) : (
        <MenuDrawer />
      )}
    </Box>
  );
};

export default Header;
