export const styles = {
  freedivingWrapper: {
    height: '98.3vw',
    background: '#F1F1F1',
    display: 'flex',
    flexDirection: 'column',
  },
  freedivingTitle: {
    fontFamily: 'Dela Gothic One',
    fontWeight: 400,
    letterSpacing: '-0.015em',
    lineHeight: '4.306vw',
    color: ' #2D2828',
    fontSize: '3.889vw',
    textTransform: 'uppercase',
    marginTop: '4.444vw',
    marginLeft: '2.222vw',
  },
  gridWrapper: {
    alignSelf: 'center',
    width: '100%',
  },
  contentGrid: (mt, ml) => ({
    marginTop: mt,
    marginLeft: ml || 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    justifyContent: 'center',
    gridGap: '1.667vw',
  }),
};
