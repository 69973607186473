import { Box, Text } from '@chakra-ui/react';
import { getCurrentYear } from 'src/utils';

import { styles } from './styles';

const Footer = () => {
  return (
    <Box sx={styles.footerWrapper}>
      <Text sx={styles.footerText}>© {getCurrentYear()}, Все права защищены</Text>
    </Box>
  );
};

export default Footer;
