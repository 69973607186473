export const styles = {
  notFoundPageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f1f1f1',
    height: '100vh',
    paddingTop: {
      base: '8.533vw',
      md: '6.452vw',
      xl: '3.333vw',
    },
    paddingX: {
      base: '3.200vw',
      xl: '2.222vw',
    },
  },
  headerBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    width: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    height: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  navText: {
    fontWeight: 600,
    color: '#2D2828',
    fontFamily: 'Inter',
    fontSize: {
      base: '4vw',
      md: '2.016vw',
      xl: '1.042vw',
    },
    marginLeft: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: {
      base: '64vw',
      md: '27.419vw',
      xl: '16.667vw',
    },
  },
  messageTitle: {
    fontFamily: 'Dela Gothic One',
    letterSpacing: '-0.015em',
    textTransform: 'uppercase',
    fontWeight: 400,
    lineHeight: '100%',
    fontSize: {
      base: '19.200vw',
      md: '9.677vw',
      xl: '5vw',
    },
  },
  subtitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
  },
  messageSubtitle: {
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '120%',
    color: '#2D2828',
    fontSize: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    marginRight: {
      base: '2.133vw',
      md: '1.075vw',
      xl: '0.556vw',
    },
  },
  messageIconWrapper: {
    width: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    height: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
  },
  messageButton: {
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#2D2828',
    width: {
      base: '100%',
      md: '39.785vw',
      xl: '20.556vw',
    },
    height: {
      base: '11.733vw',
      md: '5.914vw',
      xl: '3.056vw',
    },
    marginTop: {
      base: '19.200vw',
      md: '5.914vw',
      xl: '3.056vw',
    },
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    boxShadow: {
      base: 'box-shadow: 0 1.067vw 2.133vw rgba(0, 0, 0, 0.06);',
      md: 'box-shadow: 0 0.538vw 1.075vw rgba(0, 0, 0, 0.06);',
      xl: 'box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.06);',
    },
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
  },
};
