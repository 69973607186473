const VkIcon = ({ size = '2.3vw' } = {}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#2D2828'
        d='M18 3H6a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3zm-2.129 13.125c-.457-1.427-1.596-2.53-3.103-2.68v2.68h-.232c-4.1 0-6.438-2.816-6.536-7.5h2.054c.067 3.438 1.581 4.895 2.78 5.195V8.625h1.934v2.966c1.185-.128 2.429-1.48 2.848-2.966h1.934c-.322 1.832-1.671 3.183-2.63 3.739.959.45 2.495 1.629 3.08 3.761h-2.129z'
      ></path>
    </svg>
  );
};

export default VkIcon;
