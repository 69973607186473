export const styles = {
  titleText: {
    letterSpacing: '-0.015em',
    textTransform: 'uppercase',
    fontFamily: 'Dela Gothic One',
    color: '#fff',
    fontSize: {
      xl: '1.111vw',
      md: '2.083vw',
      base: '3.733vw',
    },
    lineHeight: '1.3vw',
  },
  subTitleText: {
    letterSpacing: '-0.015em',
    textTransform: 'uppercase',
    fontFamily: 'Dela Gothic One',
    color: '#fff',
    fontSize: {
      xl: '3.889vw',
      md: '7.292vw',
      base: '8vw',
    },
    marginBottom: '1.667vw',
  },
  bottomSubTitleText: {
    letterSpacing: '-0.015em',
    fontSize: {
      xl: '0.9vw',
      md: '1.823vw',
      base: '2.917vw',
    },
    fontFamily: 'Inter',
    color: '#183F55',
  },
  descriptionText: {
    letterSpacing: '-0.015em',
    color: '#fff',
    fontSize: {
      xl: '1.389vw',
      md: '2.604vw',
      base: '4.267vw',
    },
    fontFamily: 'Inter',
    maxWidth: {
      xl: '46.597vw',
      md: '90.188vw',
      base: '82.667vw',
    },
  },
  cardContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  bottomCardContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  bottomTextContaiter: () => ({
    padding: {
      xl: '0.556vw 0.83vw',
      md: '1.075vw 1.613vw',
      base: '2.133vw 3.200vw',
    },
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '42px',
    WebkitBorderRadius: '42px',
    MozBorderRadius: '42px',
    height: {
      xl: '2.014vw',
      md: '3.898vw',
      base: '7.733vw',
    },
  }),
  cardTextContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    textAlign: 'left',
    paddingLeft: '2.81vw',
    paddingTop: '4.8vw',
    color: '#fff',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  swiperBottom: {
    height: {
      xl: '2.014vw',
      md: '3.898vw',
      base: '7.733vw',
    },
  },
  bottomSwiperContainer: {
    height: 'auto',
    position: 'absolute',
    bottom: {
      xl: '2.167vw',
      md: '18.296vw',
      base: '34.267vw',
    },
    left: {
      xl: '50%',
      md: '10%',
      base: '-31%',
    },
    transform: {
      xl: 'translateX(-50%)',
      md: 'translateX(0%)',
      base: 'translateX(0%)',
    },
    zIndex: '2',
  },
  bottomDivider: {
    margin: '0vh 0.2vw',
    borderWidth: '0.01em',
    borderStyle: 'dashed',
    borderColor: '#fff',
    width: {
      xl: '2vw',
      md: '4.032vw',
      base: '22.400vw',
    },
  },
  buttomContainer: {
    position: 'absolute',
    right: {
      xl: '2.77vw',
      md: '4.839vw',
      base: '50%',
    },
    transform: {
      xl: 'translateX(0%)',
      md: 'translateX(0%)',
      base: 'translateX(50%)',
    },
    bottom: {
      xl: '3.194vw',
      md: '8.065vw',
      base: '16vw',
    },
    zIndex: '2',
  },
};
