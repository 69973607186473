const WhatsAppIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#2D2828'
        d='M12.038 3.291c-4.94 0-8.96 3.872-8.963 8.631a8.369 8.369 0 001.197 4.316L3 20.71l4.751-1.2a9.226 9.226 0 004.283 1.051h.004c4.94 0 8.96-3.873 8.962-8.632.001-2.305-.93-4.475-2.623-6.106-1.692-1.631-3.942-2.531-6.34-2.532zm0 15.812h-.003a7.65 7.65 0 01-3.792-1l-.273-.155-2.818.712.752-2.648-.177-.271a6.958 6.958 0 01-1.139-3.819C4.59 7.966 7.932 4.75 12.041 4.75c1.989 0 3.86.748 5.266 2.104 1.407 1.356 2.181 3.158 2.18 5.076-.002 3.956-3.343 7.174-7.45 7.174zm4.086-5.374c-.224-.107-1.325-.63-1.53-.702-.206-.071-.355-.108-.504.108-.149.216-.579.702-.709.845-.13.144-.261.162-.486.054-.224-.108-.945-.336-1.8-1.07-.666-.572-1.116-1.278-1.246-1.495-.13-.215-.014-.332.098-.44.1-.095.224-.25.336-.377.11-.126.148-.216.224-.36.074-.143.037-.27-.02-.377-.056-.109-.503-1.17-.69-1.601-.182-.42-.366-.364-.504-.371-.13-.006-.28-.008-.43-.008a.839.839 0 00-.597.27c-.205.216-.783.737-.783 1.799 0 1.062.802 2.087.914 2.231.112.144 1.58 2.323 3.826 3.258.534.22.951.354 1.277.454.536.164 1.025.14 1.41.085.43-.062 1.325-.521 1.511-1.025.187-.503.187-.935.131-1.025-.054-.09-.204-.144-.428-.253z'
      ></path>
    </svg>
  );
};

export default WhatsAppIcon;
