import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  Text,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import CloseIcon from 'src/assets/icons/CloseIcon';
import MenuIcon from 'src/assets/icons/MenuIcon';
import { IS_MD_WIDTH, PAGE_ROUTES } from 'src/constants';

import { styles } from './styles';

const MenuDrawer = ({ iconColour }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMdWidth] = useMediaQuery(IS_MD_WIDTH);
  const buttonReference = React.useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const onHandleLinkClick = (route) => {
    if (location.pathname !== '/') {
      navigate('/', {
        state: route,
      });
    }
    onClose();
  };

  return (
    <>
      <IconButton
        icon={<MenuIcon iconColour={iconColour} />}
        sx={styles.menuIcon}
        ref={buttonReference}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={buttonReference}
        size='full'
        trapFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent sx={styles.menuContainer}>
          <Box sx={styles.menuHeader}>
            <IconButton icon={<CloseIcon />} onClick={onClose} sx={styles.closeIcon}></IconButton>
          </Box>
          <Box sx={styles.menuContent}>
            <VStack sx={styles.routesList} spacing={isMdWidth ? '4.301vw' : '8.533vw'}>
              {PAGE_ROUTES.map((route) => (
                <Link
                  to={`${route.id}`}
                  key={route.id}
                  duration={1000}
                  onClick={() => onHandleLinkClick(route.id)}
                >
                  <Text sx={styles.routeText}>{route.text}</Text>
                </Link>
              ))}
            </VStack>
          </Box>
          <Box sx={styles.menuFooter}>
            <Link to='/' duration={1000} onClick={() => onHandleLinkClick('tours')}>
              <Button sx={styles.adventureButton} variant='unstyled'>
                Выбрать своё приключение
              </Button>
            </Link>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
