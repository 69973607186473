import { gql } from '@apollo/client';

import { PARTIAL_TOUR_FRAGMENT } from '../fragments';

export const GET_COMPLETED_TOURS = gql`
  ${PARTIAL_TOUR_FRAGMENT}
  query getCompletedTours {
    getTours {
      completedTours {
        ...PartialFragmentTour
      }
    }
  }
`;
