import freedivingImage1 from '../../assets/images/Freediving1.jpg';
import freedivingImage2 from '../../assets/images/Freediving2.png';
import freedivingImage3 from '../../assets/images/Freediving3.jpg';
import ImageCard from '../../components/image-card';
import TextCard from '../../components/text-card';
import { styles } from './styles';

export const FreedivingAndMeScreen = () => {
  return (
    <div style={styles.freedivingWrapper} id={'freediving-and-me'} name={'freediving-and-me'}>
      <h1 style={styles.freedivingTitle}>фридайвинг и я</h1>
      <div style={styles.gridWrapper}>
        <div style={styles.contentGrid('3.333vw', '-6.7vw')}>
          <ImageCard src={freedivingImage1} alt='img1' width={'31.667vw'} height={'18.056vw'} />
          <TextCard
            text={
              'Ещё одна часть пазла встала на место. Достижение. Знания систематизированы, закреплены и теперь я - международный инструктор по фридайвингу, готов обучать и сертифицировать молодых и подготовленных фридайверов в бассейне и на открытой воде вплоть до -38м глубины.\n\nС этого момента я буду анонсировать тут все свои водные приключения, для возможности совместных погружений и обучения фридайвингу!'
            }
            padding={'1.389vw'}
            width={'24.653vw'}
            fontSize={'1.029vw'}
            height={'20.83vw'}
            textWidth={'21.875vw'}
          />
        </div>
        <div style={styles.contentGrid('2.292vw', '3.3vw')}>
          <TextCard
            text={
              'Мой девиз: Учиться у лучших, вне рамок и границ, учить как лучшие, внедряя полученные знания и опыт.\n\nЗакончил инструкторский курс с результатами:\n-50m глубина (CWT)\n100m в длину (DYN)\n4:36 min задержка дыхания (STA)\n\nПолучил инструктора у президента AIDA Egypt, Hussein Odaiba, который обучил множество обладателей рекордов а так же является организатором соревнований и глубоководным Safety при их проведении.'
            }
            padding={'1.389vw'}
            width={'26.528vw'}
            height={'23.6vw'}
            fontSize={'1.042vw'}
            textWidth={'23.750vw'}
          />
          <ImageCard src={freedivingImage2} alt='img2' width={'31.042vw'} height={'16.667vw'} />
        </div>
        <div style={styles.contentGrid('3.333vw', '-6.7vw')}>
          <ImageCard src={freedivingImage3} alt='img1' width={'31.667vw'} height={'18.056vw'} />
          <TextCard
            text={
              'Влюблен в моря и океаны.\n\nЗанимаюсь фридайвингом 7 лет и показал "ту сторону Зазеркалья" более 3000 людям. Ныряю с китами и дельфинами, косатками и акулами. Показываю, как это делать правильно.\n\nИнструктор AIDA по фридайвингу. Синий пояс по бразильскому джиу джитсу. Увлекаюсь фристайлом и акробатикой на сноуборде, роликовых коньках. Начинающий кайтер и серфер.\n\nНачал путешествовать по России и миру, открывая  места потрясающейе красоты, раскрывающие эмоции.\nПроект freediving.space создан для того, чтоб открывать в человеке красоту окружающего мира.'
            }
            padding={'1.389vw'}
            width={'24.653vw'}
            fontSize={'1.029vw'}
            height={'30.55vw'}
            textWidth={'21.875vw'}
          />
        </div>
      </div>
    </div>
  );
};
