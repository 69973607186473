export const styles = {
  bottomButton: ({ isBackShadow }) => ({
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    padding: {
      xl: '0.833vw 1.38vw',
      md: '1.613vw 2.688vw',
      sm: '2.613vw 3.688vw',
      base: '3.200vw 5.333vw',
    },
    boxShadow: isBackShadow
      ? {
          base: '0 1.067vw 2.133vw rgba(0, 0, 0, 0.06)',
          md: '0 0.538vw 1.075vw rgba(0, 0, 0, 0.06)',
          xl: '0 0.278vw 0.556vw rgba(0, 0, 0, 0.06)',
        }
      : 'none',
    backgroundColor: '#fff',
    cursor: 'pointer',
    width: 'fit-content',
    _hover: {
      WebkitBoxShadow: isBackShadow
        ? {
            xl: 'rgba(0, 0, 0, 0.2) 0vw 0 1.042vw',
            md: 'rgba(0, 0, 0, 0.2) 0vw 0 2.016vw',
            base: 'rgba(0, 0, 0, 0.2) 0vw 0 4vw',
          }
        : {
            xl: 'rgba(255, 255, 255, 0.9) 0vw 0 1.042vw',
            md: 'rgba(255, 255, 255, 0.9) 0vw 0 2.016vw',
            base: 'rgba(255, 255, 255, 0.9) 0vw 0 4vw',
          },
      MozBoxShadow: isBackShadow
        ? {
            xl: 'rgba(0, 0, 0, 0.2) 0vw 0 1.042vw',
            md: 'rgba(0, 0, 0, 0.2) 0vw 0 2.016vw',
            base: 'rgba(0, 0, 0, 0.2) 0vw 0 4vw',
          }
        : {
            xl: 'rgba(255, 255, 255, 0.9) 0vw 0 1.042vw',
            md: 'rgba(255, 255, 255, 0.9) 0vw 0 2.016vw',
            base: 'rgba(255, 255, 255, 0.9) 0vw 0 4vw',
          },
      boxShadow: isBackShadow
        ? {
            xl: 'rgba(0, 0, 0, 0.2) 0vw 0 1.042vw',
            md: 'rgba(0, 0, 0, 0.2) 0vw 0 2.016vw',
            base: 'rgba(0, 0, 0, 0.2) 0vw 0 4vw',
          }
        : {
            xl: 'rgba(255, 255, 255, 0.9) 0vw 0 1.042vw',
            md: 'rgba(255, 255, 255, 0.9) 0vw 0 2.016vw',
            base: 'rgba(255, 255, 255, 0.9) 0vw 0 4vw',
          },
      transition: '0.5s ease',
    },
  }),
  bottomButtonLink: {
    textDecoration: 'none',
    _hover: {
      textDecoration: 'none',
    },
  },
  bottomButtonLinkText: {
    fontSize: {
      xl: '1.104vw',
      md: '2.151vw',
      sm: '3.151vw',
      base: '4.267vw',
    },
    fontFamily: 'Inter',
    fontWeight: 500,
  },
};
