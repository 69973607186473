export const styles = {
  menuContainer: {
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '#f1f1f1',
    height: '100%',
  },
  menuIcon: {
    background: 'transparent',
    width: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    height: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  menuHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: {
      base: '6.400vw',
      md: '3.226vw',
    },
    paddingRight: {
      base: '3.200vw',
      md: '4.839vw',
    },
  },
  closeIcon: {
    width: {
      base: '5.867vw',
      md: '2.957vw',
    },
    height: {
      base: '5.867vw',
      md: '2.957vw',
    },
  },
  routesList: {},
  routeText: {
    letterSpacing: '-0.01em',
    color: '#2D2828',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: {
      base: '4vw',
      md: '2.016vw',
    },
    lineHeight: {
      base: '5.333vw',
      md: '2.688vw',
    },
  },
  menuFooter: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: {
      base: '12.800vw',
      md: '6.452vw',
    },
  },
  adventureButton: {
    width: {
      base: '84.533vw',
      md: '42.608vw',
    },
    height: {
      base: '13.867vw',
      md: '6.989vw',
    },
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
    },
    color: '#183F55',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: {
      base: '0 1.067vw 2.133vw rgba(0, 0, 0, 0.06)',
      md: '0 0.538vw 1.075vw rgba(0, 0, 0, 0.06)',
    },
    fontSize: {
      base: '5.333vw',
      md: '2.688vw',
    },
    lineHeight: {
      base: '6.400vw',
      md: '3.226vw',
    },
    fontWeight: 500,
    fontFamily: 'Inter',
    _hover: {
      boxShadow: {
        base: '0.800vw 0.800vw 7.467vw rgba(255, 255, 255, 0.5)',
        md: '0.403vw 0.403vw 3.763vw rgba(255, 255, 255, 0.5)',
      },
    },
  },
};
