export const styles = {
  headerWrapper: {
    width: '100%',
    display: 'flex',
    paddingTop: {
      base: '6.400vw',
      md: '4.301vw',
      xl: '2.222vw',
    },
    paddingRight: {
      base: '3.200vw',
      md: '4.839vw',
      xl: '7.083vw',
    },
    paddingLeft: {
      base: '3.200vw',
      md: '4.839vw',
      xl: '5vw',
    },
    justifyContent: 'space-between',
    zIndex: 1,
  },
  logo: {
    height: '4vw',
  },
  logoText: {
    fontFamily: 'Dela Gothic One',
    fontWeight: '400',
    fontSize: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
    lineHeight: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    letterSpacing: '-0.01em',
    color: '#FFF',
  },
  routeText: {
    fontWeight: '400',
    fontSize: '1.042vw',
    fontFamily: 'Inter',
    lineHeight: '1.389vw',
    letterSpacing: '-0.01em',
    color: '#fff',
    cursor: 'pointer',
    _hover: {
      textDecoration: 'none',
    },
  },
};
