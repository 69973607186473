export const styles = {
  homeScreenWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  backgroundImage: {
    position: 'absolute',
    height: {
      base: '221.533vw',
      md: '158.285vw',
      xl: '55.556vw',
    },
    objectFit: 'cover',
    width: '100%',
    zIndex: -1,
  },
  titleText: {
    fontFamily: 'Dela Gothic One',
    letterSpacing: '-0.015em',
    textTransform: 'uppercase',
    color: '#fff',
    textAlign: 'center',
    marginTop: {
      base: '32.800vw',
      md: '25.941vw',
      xl: '8.889vw',
    },
    fontSize: {
      base: '9.600vw',
      md: '7.527vw',
      xl: '3.889vw',
    },
    lineHeight: {
      base: '10.667vw',
      md: '8.333vw',
      xl: '4.306vw',
    },
    width: {
      base: '93.600vw',
      md: '88.844vw',
      xl: '79.931vw',
    },
    zIndex: 1,
  },
  adventureButton: {
    background: '#f1f1f1',
    fontFamily: 'Inter',
    color: '#183F55',
    fontWeight: 500,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: {
      base: '0.800vw 0.800vw 2.667vw rgba(255, 255, 255, 0.19)',
      md: '0.403vw 0.403vw 1.344vw rgba(255, 255, 255, 0.19)',
      xl: '0.208vw 0.208vw 0.694vw rgba(255, 255, 255, 0.19)',
    },
    height: {
      base: '13.867vw',
      md: '6.989vw',
      xl: '3.611vw',
    },
    width: {
      base: '84.533vw',
      md: '42.608vw',
      xl: '22.014vw',
    },
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    fontSize: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    marginTop: {
      base: '83.467vw',
      md: '68.817vw',
      xl: '22.222vw',
    },
    _hover: {
      boxShadow: {
        base: '0.800vw 0.800vw 7.467vw rgba(255, 255, 255, 0.5)',
        md: '0.403vw 0.403vw 3.763vw rgba(255, 255, 255, 0.5)',
        xl: '0.208vw 0.208vw 1.944vw rgba(255, 255, 255, 0.5)',
      },
    },
  },
  btnText: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit',
  },
  arrowDownButtonWrapper: {
    borderRadius: '50% 50% 0 0',
    overflow: 'hidden',
    marginTop: {
      base: '10.667vw',
      md: '2.500vw',
      xl: '5.833vw',
    },
    height: {
      base: '10.667vw',
      md: '5.376vw',
      xl: '2.778vw',
    },
    width: {
      base: '21.333vw',
      md: '10.753vw',
      xl: '5.556vw',
    },
    _hover: {
      transition: '0.3s',
      filter: {
        base: 'drop-shadow(0.800vw 0.800vw 7.467vw  rgba(255, 255, 255, 0.5))',
        md: 'drop-shadow(0.403vw 0.403vw 3.763vw rgba(255, 255, 255, 0.5))',
        xl: 'drop-shadow(0.208vw 0.208vw 1.944vw rgba(255, 255, 255, 0.5))',
      },
    },
  },
  arrowDownButton: {
    background: '#f1f1f1',
    borderRadius: '50% 50% 0 0',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: {
      base: '21.333vw',
      md: '10.753vw',
      xl: '5.556vw',
    },
    width: {
      base: '21.333vw',
      md: '10.753vw',
      xl: '5.556vw',
    },
  },
  iconWrapper: {
    position: 'absolute',
    top: {
      base: '4.800vw',
      md: '2.419vw',
      xl: '1.250vw',
    },
    height: {
      base: '4vw',
      md: '2.016vw',
      xl: '1.042vw',
    },
    width: {
      base: '8vw',
      md: '4.032vw',
      xl: '2.083vw',
    },
  },
};
