const PhoneIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#2D2828'
        fillRule='evenodd'
        d='M8.469 8.769c1.163 2.317 4.388 5.542 6.705 6.705l1.616-1.541a1.222 1.222 0 011.706.02l2.435 2.434c.25.25.371.56.357.912a1.18 1.18 0 01-.428.881l-2.9 2.474c-5.312.078-14.751-9.196-14.67-14.67l2.473-2.9c.23-.27.528-.415.881-.429.353-.014.662.107.912.357l2.435 2.435c.469.469.477 1.227.02 1.707L8.469 8.769zm5.013-4.874c1.691 0 3.222.685 4.33 1.793a6.105 6.105 0 011.794 4.33.488.488 0 10.977 0c0-1.96-.795-3.735-2.08-5.02a7.079 7.079 0 00-5.02-2.08.488.488 0 000 .977zm4.798 6.123a4.783 4.783 0 00-1.405-3.393 4.783 4.783 0 00-3.393-1.405.488.488 0 100 .977 3.81 3.81 0 012.702 1.12 3.81 3.81 0 011.12 2.701.488.488 0 10.976 0zm-2.302 0c0-.689-.28-1.313-.731-1.764a2.488 2.488 0 00-1.765-.731.488.488 0 100 .976c.42 0 .8.17 1.074.445.275.275.445.655.445 1.074a.488.488 0 00.977 0z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export default PhoneIcon;
