import { Box, Button, Heading, Image, useMediaQuery } from '@chakra-ui/react';
import { Link } from 'react-scroll';
import ArrowDownIcon from 'src/assets/icons/ArrowDownIcon';
import Header from 'src/components/header';
import { IS_MD_WIDTH } from 'src/constants';

import backgroundImage from '../../assets/images/HomeScreenDesktopBackground.jpg';
import { styles } from './styles';

export const HomeScreen = () => {
  const [isMdWidth] = useMediaQuery(IS_MD_WIDTH);
  return (
    <Box sx={styles.homeScreenWrapper}>
      <Header />
      <Image src={backgroundImage} alt='background' sx={styles.backgroundImage} />
      <Heading sx={styles.titleText}>
        открой {isMdWidth ? null : <br />} для себя вселенную свободного погружения
      </Heading>
      <Button sx={styles.adventureButton} variant='unstyled'>
        <Link to='tours' style={styles.btnText} duration={1000}>
          Выбрать своё приключение
        </Link>
      </Button>
      <Box sx={styles.arrowDownButtonWrapper}>
        <Link to='freediving-and-me' duration={1000}>
          <Button sx={styles.arrowDownButton} variant='unstyled'>
            <Box sx={styles.iconWrapper}>
              <ArrowDownIcon />
            </Box>
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
