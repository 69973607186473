import { Image, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

import { styles } from './[id]/styles';

const GalleryModal = ({ isOpen, onClose, currentModalContent }) => {
  return (
    <>
      <></>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={'10vw'}>
        <ModalOverlay />
        <ModalContent sx={styles.galleryModalWrapper}>
          <Image
            loading='lazy'
            src={currentModalContent?.src}
            alt='modalImg'
            sx={styles.modalImg}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default GalleryModal;
