export const styles = {
  blogArticlePageWrapper: {
    display: 'flex',
    background: '#f1f1f1',
    flexDirection: 'column',
    minHeight: '100vh',
    paddingTop: {
      base: '8.533vw',
      md: '4.839vw',
      xl: '4.444vw',
    },
    paddingX: {
      base: '3.200vw',
      md: '4.839vw',
      xl: 'unset',
    },
    height: '100%',
  },
  headerBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: {
      xl: '2.222vw',
    },
  },
  navBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    width: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    height: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  navText: {
    fontWeight: 600,
    color: '#2D2828',
    fontFamily: 'Inter',
    fontSize: {
      base: '4vw',
      md: '2.016vw',
      xl: '1.042vw',
    },
    marginLeft: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  articleContent: {
    marginLeft: {
      xl: '16.667vw',
    },
    minWidth: {
      xl: '55.556vw',
    },
    maxWidth: {
      xl: '55.556vw',
    },
    width: '100%',
  },
  titleImage: {
    width: {
      base: '93.600vw',
      md: '90.323vw',
      xl: '55.556vw',
    },
    height: {
      base: '72vw',
      md: '69.478vw',
      xl: '27.778vw',
    },
    objectFit: 'cover',
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    marginTop: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '3.333vw',
    },
    marginBottom: {
      base: '8.533vw',
      md: '4.301vw',
      xl: '3.333vw',
    },
  },
  articleTitle: {
    letterSpacing: '-0.015em',
    textTransform: 'uppercase',
    fontWeight: 400,
    fontFamily: 'Dela Gothic One',
    color: '#2D2828',
    fontSize: {
      base: '8vw',
      md: '4.032vw',
      xl: '2.222vw',
    },
    lineHeight: {
      base: '8.533vw',
      md: '4.301vw',
      xl: '2.431vw',
    },
    marginBottom: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.944vw',
    },
    width: {
      xl: '51.736vw',
    },
  },
  articleSubtitle: {
    color: '#333',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    lineHeight: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    width: {
      xl: '47vw',
    },
    marginBottom: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  articleText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#2D2828',
    fontSize: {
      base: '4vw',
      md: '2.016vw',
      xl: '1.042vw',
    },
    lineHeight: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    width: {
      xl: '40.486vw',
    },
  },
  quoteBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    marginTop: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    marginBottom: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  quoteLine: {
    background: '#478C91',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    width: {
      base: '2.133vw',
      md: '1.075vw',
      xl: '0.556vw',
    },
  },
  quoteText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    lineHeight: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    width: {
      base: '72.800vw',
      md: '36.694vw',
      xl: '43.125vw',
    },
    marginLeft: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.667vw',
    },
    paddingTop: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.667vw',
    },
    paddingBottom: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.667vw',
    },
  },
  articleImage: {
    width: {
      base: '93.600vw',
      md: '90.323vw',
      xl: '55.556vw',
    },
    height: {
      base: '52.800vw',
      md: '50.952vw',
      xl: '31.250vw',
    },
    objectFit: 'cover',
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    marginTop: {
      base: '8.533vw',
      md: '4.301vw',
      xl: '3.611vw',
    },
    marginBottom: {
      base: '8.533vw',
      md: '4.301vw',
      xl: '2.431vw',
    },
  },
  inArticleTitle: {
    width: {
      xl: '51.736vw',
    },
    fontFamily: 'Dela Gothic One',
    color: '#333',
    fontWeight: 400,
    fontSize: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    lineHeight: {
      base: '9.333vw',
      md: '4.704vw',
      xl: '2.431vw',
    },
    marginBottom: {
      base: '8.533vw',
      md: '3.226vw',
      xl: '2.153vw',
    },
  },
  articleList: {
    marginTop: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    marginBottom: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  listItemWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  listDot: {
    borderRadius: 'full',
    background: '#478C91',
    height: {
      base: '2.133vw',
      md: '1.075vw',
      xl: '0.556vw',
    },
    width: {
      base: '2.133vw',
      md: '1.075vw',
      xl: '0.556vw',
    },
    marginRight: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
  },
  listText: {
    fontFamily: 'Inter',
    fontWeight: 800,
    color: '#333',
    fontSize: {
      base: '4vw',
      md: '2.016vw',
      xl: '1.042vw',
    },
    lineHeight: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  bottomMargin: {
    marginTop: {
      base: '19.200vw',
      md: '10.753vw',
      xl: '8.333vw',
    },
  },
  loadingSpinner: {
    position: 'absolute',
    height: '15vw',
    width: '15vw',
    top: '40%',
    alignSelf: 'center',
  },
};
