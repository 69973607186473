export const styles = {
  footerWrapper: {
    display: 'flex',
    width: '100%',
    background: '#1B282D',
    alignItems: 'center',
    justifyContent: {
      base: 'center',
      xl: 'flex-end',
    },
    height: {
      base: '12.800vw',
      md: '6.452vw',
      xl: '3.333vw',
    },
  },
  footerText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#F1F1F1',
    fontSize: {
      base: '3.467vw',
      md: '1.747vw',
      xl: '0.903vw',
    },
    lineHeight: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
    marginRight: {
      base: '0',
      xl: '2.222vw',
    },
  },
};
