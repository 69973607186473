// Прогони у себя через линтер, пожалуйста. У меня не хочет эту строчку фиксить.
// eslint-disable-next-line object-curly-newline
import { Box, Button, Heading, Image, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-scroll';
import ArrowUpIcon from 'src/assets/icons/ArrowUpIcon';
import backgroundImage from 'src/assets/images/ContactsDesktopBackground.png';
import mobileBackgroundImage from 'src/assets/images/ContactsMobileBackground.png';
import tabletBackgroundImage from 'src/assets/images/ContactsTabletBackground.png';
import { IS_MD_WIDTH, IS_XL_WIDTH, SOCIAL_MEDIA } from 'src/constants';

import { styles } from './styles';

export const ContactsScreen = () => {
  const [phoneVisibility, setPhoneVisibility] = React.useState(false);
  const [isXlWidth] = useMediaQuery(IS_XL_WIDTH);
  const [isMdWidth] = useMediaQuery(IS_MD_WIDTH);

  const handleShowPhone = (text) => {
    if (text === 'телефон') {
      setPhoneVisibility(true);
    }
  };

  return (
    <Box sx={styles.contactsScreenWrapper} id={'contacts'} name={'contacts'}>
      <Image
        loading='lazy'
        src={
          isXlWidth ? backgroundImage : isMdWidth ? tabletBackgroundImage : mobileBackgroundImage
        }
        alt='background'
        sx={styles.backgroundImage}
      />
      <Box sx={styles.headerWrapper}>
        <Heading sx={styles.titleText}>КАК СВЯЗАТЬСЯ СО МНОЙ</Heading>
        {isXlWidth ? (
          <Button sx={styles.toHomeButton} variant='unstyled'>
            <a href='#home' style={styles.upLink}>
              <Box sx={styles.btnIconWrapper}>
                <ArrowUpIcon />
              </Box>
              <Text sx={styles.buttonText}>Наверх</Text>
            </a>
          </Button>
        ) : null}
      </Box>
      <Text sx={styles.subtitleText}>Множество контента есть в социальных сетях по ссылкам:</Text>
      <SimpleGrid
        sx={styles.contactsGrid}
        spacingY={isXlWidth ? '0.833vw' : isMdWidth ? '1.613vw' : '3.200vw'}
        spacingX={0}
        columns={isMdWidth ? 3 : 2}
      >
        {SOCIAL_MEDIA.map((card) => (
          <Button
            sx={styles.contactButton}
            key={card.id}
            variant='unstyled'
            onClick={() => {
              handleShowPhone(card.text);
            }}
          >
            {card.text === 'телефон' ? (
              <Box style={styles.labelLink}>
                {phoneVisibility ? (
                  <Text sx={styles.labelText}>{!phoneVisibility ? card.text : '+79212884363'}</Text>
                ) : (
                  <>
                    <Box sx={styles.labelIconWrapper}>{card.icon}</Box>
                    <Text sx={styles.labelText}>{card.text}</Text>
                  </>
                )}
              </Box>
            ) : (
              <a href={card.link} style={styles.labelLink} target='_blank'>
                <Box sx={styles.labelIconWrapper}>{card.icon}</Box>
                <Text sx={styles.labelText}>{card.text}</Text>
              </a>
            )}
          </Button>
        ))}
      </SimpleGrid>
      {!isXlWidth ? (
        <Button sx={styles.toHomeButton} variant='unstyled'>
          <Link to='home' style={styles.upLink} duration={1000}>
            <Box sx={styles.btnIconWrapper}>
              <ArrowUpIcon />
            </Box>
            <Text sx={styles.buttonText}>Наверх</Text>
          </Link>
        </Button>
      ) : null}
    </Box>
  );
};
