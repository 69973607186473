export const styles = {
  textWrapper: (padding, width, height) => ({
    padding,
    borderRadius: '0.833vw',
    boxShadow: '0 0.278vw 0.556vw rgba(0, 0, 0, 0.06)',
    background: '#fff',
    width,
    height,
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
  }),
  text: (fontSize, textWidth) => ({
    fontSize,
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '1.389vw',
    color: '#2D2828',
    fontStyle: 'normal',
    position: 'absolute',
    width: textWidth,
    boxSize: 'border-box',
    textAlign: 'left',
  }),
};
