import { Box, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import VkIcon from 'src/assets/icons/VkIcon';

import { styles } from './styles';

export const Card = ({ card }) => {
  const size = useBreakpointValue({
    xl: '2.3vw',
    md: '4.626vw',
    base: '7.800vw',
  });
  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.textCardContainer}>
        <Box sx={styles.textCardContainerHeader}>
          <Text sx={styles.reviewName}>{`${card.userFirstName} ${card.userLastName}`}</Text>
          <VkIcon size={size} />
        </Box>
        <Text sx={styles.textReview}>{card.text}</Text>
      </Box>
      <Box>
        <Image loading='lazy' sx={styles.cardImage} src={card.photoUrl} alt={'photo'} />
      </Box>
    </Box>
  );
};
