import {
  Box,
  Button,
  Heading,
  IconButton,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import CloseIcon from 'src/assets/icons/CloseIcon';
import PhoneIcon from 'src/assets/icons/PhoneIcon';
import TelegramIcon from 'src/assets/icons/TelegramIcon';
import WhatsAppIcon from 'src/assets/icons/WhatsAppIcon';
import { IS_MD_WIDTH, IS_XL_WIDTH } from 'src/constants';

import { styles } from './styles';

export const TripModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [animation, setAnimation] = React.useState(false);
  const [isMdWidth] = useMediaQuery(IS_MD_WIDTH);
  const [isXlWidth] = useMediaQuery(IS_XL_WIDTH);

  const handlePlayAnimation = () => {
    setAnimation(true);
    setTimeout(() => setAnimation(false), 1000);
  };
  return (
    <>
      <Button onClick={onOpen} sx={styles.modalBtn} variant='unstyled'>
        Отправиться в приключение
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent sx={styles.modalWrapper} mr={isXlWidth ? '60%' : 'unset'}>
          <IconButton
            icon={<CloseIcon />}
            variant='unstyled'
            sx={styles.modalCloseBtn}
            onClick={onClose}
          />
          <Box sx={styles.modalContent}>
            <Heading sx={styles.modalTitle}>Напишите или позвоните мне!</Heading>
            <Box sx={styles.modalNumberContainer} color={animation ? 'green.600' : '#2D2828'}>
              +7(921)288-43-63
            </Box>
            <Stack spacing={'3%'} direction={isMdWidth ? 'row' : 'column'}>
              <Link href={'https://t.me/FinKoBapHbIu'} sx={styles.contactLabel} isExternal>
                <Box sx={styles.contactIconWrapper}>
                  <TelegramIcon />
                </Box>
                <Text sx={styles.contactText}>telegram</Text>
              </Link>
              <Box sx={styles.contactLabel} onClick={handlePlayAnimation}>
                <Box sx={styles.contactIconWrapper}>
                  <WhatsAppIcon />
                </Box>
                <Text sx={styles.contactText}>whatsapp</Text>
              </Box>
              <Box sx={styles.contactLabel} onClick={handlePlayAnimation}>
                <Box sx={styles.contactIconWrapper}>
                  <PhoneIcon />
                </Box>
                <Text sx={styles.contactText}>телефон</Text>
              </Box>
            </Stack>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
