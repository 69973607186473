export const styles = {
  titleText: {
    letterSpacing: '-0.015em',
    textTransform: 'uppercase',
    fontFamily: 'Dela Gothic One',
    fontSize: {
      xl: '3.889vw',
      md: '7.527vw',
      base: '8.033vw',
    },
    lineHeight: {
      xl: '4.236vw',
      md: '8.199vw',
      base: '8.533vw',
    },
    maxWidth: {
      xl: '85.417vw',
      md: '76.613vw',
      base: '91.733vw',
    },
    color: '#000',
  },
  subTitleText: {
    letterSpacing: '-0.015em',
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: {
      xl: '3.889vw',
      md: '7.292vw',
      base: '8vw',
    },
    marginBottom: '1.667vw',
  },
  reviewName: {
    letterSpacing: '-0.015em',
    fontFamily: 'Dela Gothic One',
    color: '#2D2828',
    fontSize: {
      xl: '1.667vw',
      md: '3.226vw',
      base: '6.400vw',
    },
    textTransform: 'uppercase',
    width: 'fit-content',
    marginRight: {
      xl: '0.833vw',
      md: '1.613vw',
      base: '3.200vw',
    },
  },
  textReview: {
    color: '#2D2828',
    fontSize: {
      xl: '1.042vw',
      md: '2.016vw',
      base: '4vw',
    },
    maxHeight: {
      xl: '20.500vw',
      md: '14.785vw',
      base: '80vw',
    },
    overflowY: 'hidden',
    fontFamily: 'Inter',
    marginTop: {
      xl: '0.972vw',
      md: '1.882vw',
      base: '3.733vw',
    },
  },
  cardContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: {
      xl: 'flex-start',
      md: 'center',
      base: 'center',
    },
    justifyContent: {
      xl: 'flex-end',
      md: 'flex-start',
      base: 'flex-start',
    },
    flexDirection: {
      xl: 'row',
      md: 'column',
      base: 'column',
    },
    maxWidth: {
      xl: '84.939vw',
      md: '100%',
      base: '100%',
    },
    marginTop: {
      xl: '4.861vw',
      md: '4.839vw',
      base: '9.600vw',
    },
  },
  textCardContainer: {
    width: {
      xl: '29.861vw',
      md: '100%',
      base: '100%',
    },
    height: 'fit-content',
    backgroundColor: '#fff',
    borderRadius: {
      xl: '0.556vw',
      md: '1.075vw',
      base: '2.133vw',
    },
    padding: {
      xl: '2.222vw 1.667vw',
      md: '3.226vw',
      base: '6.400vw',
    },
    marginRight: {
      xl: '1.667vw',
      md: '0px',
      base: '0px',
    },
    marginBottom: {
      xl: '0vw',
      md: '2.151vw',
      base: '4.267vw',
    },
  },
  textCardContainerHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  cardTextContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    textAlign: 'left',
    paddingLeft: '2.81vw',
    paddingTop: '4.8vw',
    color: '#fff',
  },
  mainContainer: {
    width: '100%',
    height: {
      xl: '54.556vw',
      md: '139.247vw',
      base: '226.533vw',
    },
    position: 'relative',
  },
  contentContainer: {
    paddingTop: {
      xl: '4.444vw',
      md: '6.452vw',
      base: '8.533vw',
    },
    paddingLeft: {
      xl: '2.222vw',
      md: '4.839vw',
      base: '3.200vw',
    },
    paddingRight: {
      xl: '2.222vw',
      md: '4.839vw',
      base: '3.200vw',
    },
  },
  cardImage: {
    borderRadius: {
      xl: '0.556vw',
      md: '1.075vw',
      base: '2.133vw',
    },
    objectFit: 'cover',
    width: {
      xl: '48.611vw',
      md: '90.323vw',
      base: '93.600vw',
    },
    height: {
      xl: '33.611vw',
      md: '53.763vw',
      base: '51.733vw',
    },
  },
};
