import { Box, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { styles } from './styles';

export const CarouselCard = ({ articleData }) => {
  return (
    // eslint-disable-next-line no-underscore-dangle
    <Link to={`article/${articleData?._id}`}>
      <Box sx={styles.cardWrapper}>
        <Image
          loading='lazy'
          src={articleData?.photoUrl}
          alt={articleData?.photoName || 'cardImage'}
          sx={styles.cardImage}
        />
        <Box sx={styles.cardTextContentWrapper}>
          <Text sx={styles.cardTitle}>{articleData?.title}</Text>
          <Box sx={styles.cardTextWrapper}>
            <Text sx={styles.cardText}>{articleData?.description}</Text>
          </Box>
          <Box sx={styles.buttonWrapper}>
            <Text sx={styles.readMoreText}>Читать далее</Text>
          </Box>
        </Box>
        <Box sx={styles.gradient} />
      </Box>
    </Link>
  );
};
