import { gql } from '@apollo/client';

import { PARTIAL_TOUR_FRAGMENT } from '../fragments';

export const GET_UPCOMING_TOURS = gql`
  ${PARTIAL_TOUR_FRAGMENT}
  query getUpcomingTours {
    getTours {
      upcomingTours {
        ...PartialFragmentTour
      }
    }
  }
`;
