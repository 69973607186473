import { useQuery } from '@apollo/client';

import * as QUERIES from '../graphql/queries';

export const useGetBlogArticleQuery = (options) => {
  return useQuery(QUERIES.GET_BLOG_ARTICLE, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetUpcomingToursQuery = (options) => {
  return useQuery(QUERIES.GET_UPCOMING_TOURS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useGetBlogArticlesByCategoryQuery = (options) => {
  return useQuery(QUERIES.GET_BLOG_ARTICLES_BY_CATEGORY, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetCompletedToursQuery = (options) => {
  return useQuery(QUERIES.GET_COMPLETED_TOURS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useGetReviewsQuery = (options) => {
  return useQuery(QUERIES.GET_REVIEWS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useGetTourQuery = (options) => {
  return useQuery(QUERIES.GET_TOUR, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};
