import { gql } from '@apollo/client';

export const FULL_BLOG_ARTICLE = gql`
  fragment FullFragmentArticle on BlogArticle {
    _id
    photoName
    difficultyLevel
    isMyTravel
    title
    content
    createdAt
    photoUrl
    description
    __typename
  }
`;

export const PARTIAL_BLOG_ARTICLE = gql`
  fragment PartialFragmentArticle on BlogArticle {
    _id
    photoName
    difficultyLevel
    isMyTravel
    title
    createdAt
    photoUrl
    description
    __typename
  }
`;
