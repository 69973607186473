export const styles = {
  blogScreenWrapper: {
    background: '#F1F1F1',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingTop: {
      base: '8.533vw',
      md: '6.452vw',
      xl: '4.444vw',
    },
  },
  titleText: {
    fontFamily: 'Dela Gothic One',
    fontWeight: 400,
    letterSpacing: '-0.015em',
    textTransform: 'uppercase',
    lineHeight: '110%',
    color: '#2D2828',
    fontSize: {
      base: '8vw',
      md: '7.527vw',
      xl: '3.889vw',
    },
    marginLeft: {
      base: '3.200vw',
      md: '4.839vw',
      xl: '2.222vw',
    },
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#2D2828',
    lineHeight: '120%',
    fontSize: {
      base: '4.267vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    marginLeft: {
      base: '3.200vw',
      md: '4.839vw',
      xl: '2.222vw',
    },
    marginTop: {
      base: '3.200vw',
      md: '2.688vw',
      xl: '1.667vw',
    },
    marginBottom: {
      base: '9.600vw',
      md: '6.452vw',
      xl: '3.333vw',
    },
  },
  blogButtonWrapper: {
    alignSelf: 'center',
    marginTop: {
      base: '12.800vw',
      md: '6.452vw',
      xl: '3.333vw',
    },
    marginBottom: {
      base: '15.467vw',
      md: '9.677vw',
      xl: '2.986vw',
    },
    color: '#2D2828',
  },
  carouselWrapper: {
    paddingRight: {
      base: '3.200vw',
      md: '4.839vw',
      xl: '1vw',
    },
  },
  cardWrapper: {
    boxShadow: {
      base: 'box-shadow: 0 1.067vw 2.133vw rgba(0, 0, 0, 0.06)',
      md: 'box-shadow: 0 0.538vw 1.075vw rgba(0, 0, 0, 0.06)',
      xl: 'box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.06)',
    },
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: {
      base: '82.667vw',
      md: '57.796vw',
      xl: '29.861vw',
    },
    height: {
      base: '136vw',
      md: '66.935vw',
      xl: '34.583vw',
    },
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  cardImage: {
    objectFit: 'cover',
    cursor: 'pointer',
    height: {
      base: '64.800vw',
      md: '33.468vw',
      xl: '17.292vw',
    },
    width: 'auto',
  },
  cardTextContentWrapper: {
    marginTop: {
      base: '5.333vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    paddingRight: {
      base: '5.333vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    paddingLeft: {
      base: '5.333vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
  },
  cardTitle: {
    color: '#2D2828',
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: {
      base: '4.533vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    lineHeight: {
      base: '5.600vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
  },
  cardTextWrapper: {
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    marginTop: {
      base: '2.667vw',
      md: '1.344vw',
      xl: '0.694vw',
    },
    height: {
      base: '41vw',
      md: '18vw',
      xl: '8.2vw',
    },
  },
  cardText: {
    fontFamily: 'Inter',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: {
      base: '4vw',
      md: '2.04vw',
      xl: '1.042vw',
    },
    lineHeight: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  buttonWrapper: {
    display: 'flex',
    position: 'absolute',
    bottom: {
      base: '9.600vw',
      md: '4.973vw',
      xl: '2.569vw',
    },
    alignItems: 'flex-end',
    zIndex: 4,
  },
  readMoreText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    color: '#2D2828',
    cursor: 'pointer',
    fontSize: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
    lineHeight: {
      base: '5.600vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  gradient: {
    position: 'absolute',
    bottom: 0,
    width: {
      base: '82.667vw',
      md: '57.796vw',
      xl: '29.861vw',
    },
    height: '25%',
    background:
      'linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 55%, rgba(255,255,255,0) 100%)',
    zIndex: 3,
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
};
