import { Box, Button, Heading, Text, useMediaQuery } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import NavLeftArrowIcon from 'src/assets/icons/NavLeftArrowIcon';
import SadFaceIcon from 'src/assets/icons/SadFaceIcon';
import MenuDrawer from 'src/components/menu-drawer';
import { IS_XL_WIDTH } from 'src/constants';

import { styles } from './styles';

const NotFoundPage = () => {
  const [isXlWidth] = useMediaQuery(IS_XL_WIDTH);
  const navigate = useNavigate();
  return (
    <Box sx={styles.notFoundPageWrapper}>
      <Box sx={styles.headerBlock}>
        <Link to='/'>
          <Box sx={styles.navBlock}>
            <Box sx={styles.iconWrapper}>
              <NavLeftArrowIcon />
            </Box>
            <Text sx={styles.navText}>На главную</Text>
          </Box>
        </Link>
        {isXlWidth ? null : <MenuDrawer iconColour={'#2D2828'} />}
      </Box>
      <Box sx={styles.messageWrapper}>
        <Heading sx={styles.messageTitle}>404</Heading>
        <Box sx={styles.subtitleWrapper}>
          <Text sx={styles.messageSubtitle}>Произошла ошибка</Text>
          <Box sx={styles.messageIconWrapper}>
            <SadFaceIcon />
          </Box>
        </Box>
        <Button sx={styles.messageButton} variant='unstyled' onClick={() => navigate('/')}>
          Вернуться на главную страницу
        </Button>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
