export const styles = {
  toursScreenWrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f1f1f1',
    paddingTop: {
      base: '8.533vw',
      md: '6.452vw',
      xl: '4.444vw',
    },
  },
  titleText: {
    fontFamily: 'Dela Gothic One',
    textTransform: 'uppercase',
    letterSpasing: '-0.015em',
    fontWeight: 400,
    fontSize: {
      base: '8vw',
      md: '7.527vw',
      xl: '3.889vw',
    },
    lineHeight: {
      base: '8.533vw',
      md: '8.333vw',
      xl: '4.306vw',
    },
    marginTop: {
      base: '3.200vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    paddingLeft: {
      base: '3.200vw',
      md: '4.839vw',
      xl: '2.222vw',
    },
  },
  subtitleText: {
    fontWeight: 400,
    fontFamily: 'Inter',
    color: '#2D2828',
    marginTop: {
      base: '3.200vw',
      md: '2.688vw',
      xl: '1.667vw',
    },
    fontSize: {
      base: '4.267vw',
      md: '2.6vw',
      xl: '1.389vw',
    },
    lineHeight: {
      base: '5.333vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    maxWidth: {
      base: '79.200vw',
      md: '72.312vw',
      xl: '63vw',
    },
    marginBottom: {
      base: '6.400vw',
      md: '6.452vw',
      xl: '1.667vw',
    },
    paddingLeft: {
      base: '3.200vw',
      md: '4.839vw',
      xl: '2.222vw',
    },
  },
  contactButton: {
    height: {
      base: '11.733vw',
      md: '5.914vw',
      xl: '3.056vw',
    },
    alignSelf: 'center',
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    marginTop: {
      base: '6.400vw',
      md: '6.452vw',
      xl: '3.056vw',
    },
    cursor: 'pointer',
    marginBottom: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  contactLink: {
    width: 'inherit',
    height: 'inherit',
    borderRadius: 'inherit',
    color: '#2D2828',
    fontFamily: 'Inter',
    lineHeight: '125%',
    fontWeight: 500,
    fontSize: 'inherit',
  },
  tipWrapper: {
    alignSelf: 'center',
    display: 'flex',
    marginBottom: {
      base: '16.533vw',
      md: '6.586vw',
      xl: '3.264vw',
    },
  },
  tipText: {
    color: '#565656',
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: '125%',
    fontSize: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
    marginRight: {
      base: '1.600vw',
      md: '0.806vw',
      xl: '0.417vw',
    },
  },
  iconWrapper: {
    height: {
      base: '5.867vw',
      md: '2.957vw',
      xl: '1.528vw',
    },
    width: {
      base: '5.867vw',
      md: '2.957vw',
      xl: '1.528vw',
    },
  },
  swiperContainer: {
    paddingRight: {
      base: '3.200vw',
      md: '4.839vw',
      xl: '2.222vw',
    },
  },
  cardWrapper: {
    height: {
      base: '102.667vw',
      md: '51.747vw',
      xl: '26.736vw',
    },
    background: 'gray',
    width: {
      base: '82.667vw',
      md: '51.747vw',
      xl: '26.736vw',
    },
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    overflow: 'hidden',
  },
  cardImage: {
    width: 'inherit',
    height: 'inherit',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  difficultyLabel: {
    background: '#fff',
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: {
      base: '43.467vw',
      md: '21.909vw',
      xl: '11.319vw',
    },
    height: {
      base: '14.667vw',
      md: '7.392vw',
      xl: '3.819vw',
    },
    borderRadius: {
      base: '2.133vw',
      md: '1.075vw',
      xl: '0.556vw',
    },
    top: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    right: {
      base: '2.667vw',
      md: '1.344vw',
      xl: '0.694vw',
    },
  },
  labelInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: {
      base: '8vw',
      md: '4.032vw',
      xl: '2.083vw',
    },
    marginTop: {
      base: '1.600vw',
      md: '0.806vw',
      xl: '0.417vw',
    },
  },
  labelText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    color: '#2D2828',
    fontSize: {
      base: '2.667vw',
      md: '1.344vw',
      xl: '0.694vw',
    },
    lineHeight: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  labelIconWrapper: {
    width: {
      base: '7.200vw',
      md: '3.629vw',
      xl: '1.875vw',
    },
    height: {
      base: '7.200vw',
      md: '3.629vw',
      xl: '1.875vw',
    },
  },
  popoverIconWrapper: {
    height: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    width: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    marginLeft: {
      base: '1.067vw',
      md: '0.538vw',
      xl: '0.278vw',
    },
  },
  difficultyIconsWrapper: {
    display: 'flex',
    alignSelf: 'center',
    marginTop: {
      base: '1.600vw',
      md: '0.806vw',
      xl: '0.417vw',
    },
  },
  popoverContent: {
    background: '#F1F1F1',
    _focus: {
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    padding: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    zIndex: 10,
    maxWidth: {
      base: '84.267vw',
      md: '42.473vw',
      xl: '21.944vw',
    },
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    boxShadow: {
      base: '0 0 2.133vw rgba(0, 0, 0, 0.26)',
      md: '0 0 1.075vw rgba(0, 0, 0, 0.26)',
      xl: '0 0 0.556vw rgba(0, 0, 0, 0.26)',
    },
  },
  tipTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    letterSpasing: '-0.01em',
    color: '#2D2828',
    lineHeight: '120%',
    fontSize: {
      base: '3.733vw',
      md: '1.882vw',
      xl: '0.972vw',
    },
  },
  tipContent: {
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#2D2828',
    fontSize: {
      base: '3.733vw',
      md: '1.882vw',
      xl: '0.972vw',
    },
    lineHeight: {
      base: '4.533vw',
      md: '2.285vw',
      xl: '1.181vw',
    },
    marginTop: {
      base: '2.667vw',
      md: '1.344vw',
      xl: '0.694vw',
    },
  },
  cardTitle: {
    position: 'absolute',
    color: '#fff',
    fontFamily: 'Dela Gothic One',
    fontWeight: 400,
    fontSize: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    lineHeight: {
      base: '9.333vw',
      md: '4.704vw',
      xl: '2.431vw',
    },
    top: {
      base: '74vw',
      md: '37vw',
      xl: '19vw',
    },
    left: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    maxWidth: {
      base: '63.467vw',
      md: '31.989vw',
      xl: '16.528vw',
    },
  },
};
