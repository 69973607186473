import React from 'react';

export const useIntersectionObserver = () => {
  const [node, setNode] = React.useState(null);
  const [intersecting, setIntersecting] = React.useState('not intersecting');

  const observer = React.useRef(
    new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIntersecting('intersecting');
        }
      },
      {
        threshold: 1,
        rootMargin: '15% 0%',
      },
    ),
  );

  React.useEffect(() => {
    const currentObserver = observer.current;

    if (node) {
      currentObserver.observe(node);
    }

    return () => {
      if (node) {
        currentObserver.unobserve(node);
        setIntersecting('not intersecting');
      }
    };
  }, [node]);

  return {
    node: setNode,
    intersecting,
  };
};
