import { Text, Wrap, WrapItem } from '@chakra-ui/react';

import { styles } from './styles';

export const InfoLabel = ({ labelTitle, labelText }) => {
  const proccessLabelText = () => {
    switch (labelText) {
      case 'BEGINNER':
        return 'Для начинающих';
      case 'AMATEUR':
        return 'Для любителей';
      case 'ADVANCED':
        return 'Для продвинутых';
      case 'EXPERT':
        return 'Для экспертов';
      case 'NONE':
        return 'Без гостиницы';
      case 'REGULAR_HOTEL_WITH_NO_MEALS':
        return 'Гостиница без питания';
      case 'REGULAR_HOTEL_WITH_MEALS':
        return 'Гостиница с питанием';
      case 'COMFORTABLE_HOTEL_WITH_NO_MEALS':
        return 'Комфортная гостиница без питания';
      case 'COMFORTABLE_HOTEL_WITH_MEALS':
        return 'Комфортная гостиница с питанием';
      default:
        return labelText;
    }
  };

  return (
    <WrapItem sx={styles.labelItem}>
      <Wrap sx={styles.labelTextWrapper}>
        <Text sx={styles.labelKey}>{labelTitle}</Text>
        <Text sx={styles.labelValue}>{proccessLabelText()}</Text>
      </Wrap>
    </WrapItem>
  );
};
