import { Route, Routes } from 'react-router-dom';
import NotFoundPage from 'src/pages/404';
import Blog from 'src/pages/blog';
import BlogArticle from 'src/pages/blog/[id]/BlogArticle';
import Home from 'src/pages/home';
import TripArticle from 'src/pages/trip/[id]/TripArticle';

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='blog/' element={<Blog />} />
      <Route path='blog/article/:id' element={<BlogArticle />} />
      <Route path='trip/article/:id' element={<TripArticle />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
