import 'swiper/css';
import './style-module.css';

import { Box, Spinner, Text, useMediaQuery } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NavLeftArrowIcon from 'src/assets/icons/NavLeftArrowIcon';
import Footer from 'src/components/footer';
import MenuDrawer from 'src/components/menu-drawer';
import { IS_XL_WIDTH } from 'src/constants';
import { useGetBlogArticlesByCategoryQuery } from 'src/hooks';
import { scrollToTop } from 'src/utils/scrollToTop';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CarouselCard } from './CarouselCard';
import { styles } from './styles';

const Blog = () => {
  const [isXlWidth] = useMediaQuery(IS_XL_WIDTH);
  const { pathname } = useLocation();
  const { data: blogs, loading } = useGetBlogArticlesByCategoryQuery();
  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <>
      <Box sx={styles.blogPageWrapper}>
        {!loading ? (
          <>
            <Box sx={styles.headerBlock}>
              <Link to='/'>
                <Box sx={styles.navBlock}>
                  <Box sx={styles.iconWrapper}>
                    <NavLeftArrowIcon />
                  </Box>
                  <Text sx={styles.navText}>На главную</Text>
                </Box>
              </Link>
              {isXlWidth ? null : <MenuDrawer iconColour={'#2D2828'} />}
            </Box>
            <Text sx={styles.titleText}>ПОГОВОРИМ ПРО ФРИДАЙВИНГ?</Text>
            <Text sx={styles.subtitleText}>
              Здесь будет собрана информация о технике, снаряжении, психологии и философии
              фридайвинга. Статьи написанные мной, без копипаста.
            </Text>
            {blogs?.getBlogArticlesByCategory?.new.length > 0 ? (
              <Box sx={styles.blogCategoryContainer}>
                <Text sx={styles.blogTitleText}>Новое</Text>
                <Swiper
                  id={'blog-page-swiper'}
                  slidesPerView={'auto'}
                  spaceBetween={32}
                  freeMode={{
                    enabled: true,
                    minimumVelocity: 7,
                  }}
                  resistanceRatio={0}
                >
                  {blogs?.getBlogArticlesByCategory?.new.map((article, index) => (
                    <SwiperSlide key={index} id={'blog-page-swiper-slide'}>
                      <CarouselCard articleData={article} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            ) : null}
            {blogs?.getBlogArticlesByCategory?.forBeginners > 0 ? (
              <Box sx={styles.blogCategoryContainer}>
                <Text sx={styles.blogTitleText}>Новичкам</Text>
                <Swiper
                  id={'blog-page-swiper'}
                  slidesPerView={'auto'}
                  spaceBetween={32}
                  freeMode={{
                    enabled: true,
                    minimumVelocity: 7,
                  }}
                  resistanceRatio={0}
                >
                  {blogs?.getBlogArticlesByCategory?.forBeginners.map((article, index) => (
                    <SwiperSlide key={index} id={'blog-page-swiper-slide'}>
                      <CarouselCard articleData={article} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            ) : null}
            {blogs?.getBlogArticlesByCategory?.myTravels.length > 0 ? (
              <Box sx={styles.blogCategoryContainer}>
                <Text sx={styles.blogTitleText}>Мои путешествия</Text>
                <Swiper
                  id={'blog-page-swiper'}
                  slidesPerView={'auto'}
                  spaceBetween={32}
                  freeMode={{
                    enabled: true,
                    minimumVelocity: 7,
                  }}
                  resistanceRatio={0}
                >
                  {blogs?.getBlogArticlesByCategory?.myTravels.map((article, index) => (
                    <SwiperSlide key={index} id={'blog-page-swiper-slide'}>
                      <CarouselCard articleData={article} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            ) : null}
            <Box sx={styles.bottomMargin} />
          </>
        ) : (
          <Spinner
            thickness='0.7vw'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.200'
            sx={styles.loadingSpinner}
          />
        )}
      </Box>
      {!loading ? <Footer /> : null}
    </>
  );
};

export default Blog;
