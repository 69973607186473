import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import Footer from '../footer';
import { styles } from './styles';

const Layout = ({ children }) => {
  const location = useLocation();
  return (
    <Box sx={styles.layoutWrapper}>
      <main>{children}</main>
      {location.pathname === '/' ? <Footer /> : null}
    </Box>
  );
};

export default Layout;
