export const styles = {
  imageWrapper: (width, height) => ({
    minWidth: width,
    minHeight: height,
    width,
    height,
    borderRadius: '0.833vw',
    filter: 'drop-shadow(0vw 0.278vw 0.556vw rgba(0, 0, 0, 0.06))',
    overflow: 'hidden',
  }),
  image: {
    width: 'inherit',
    height: 'inherit',
    objectFit: 'cover',
  },
};
