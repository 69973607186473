import { Box, Link as InternetLink, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { styles } from './styles';

const WhiteButton = ({ text, link, inBlog, isInternetLink, isBackShadow }) => {
  return isInternetLink ? (
    <InternetLink href={link} sx={styles.bottomButtonLink}>
      <Box
        sx={styles.bottomButton({
          isBackShadow,
        })}
      >
        <Text sx={styles.bottomButtonLinkText}>{text}</Text>
      </Box>
    </InternetLink>
  ) : (
    <Link to={link}>
      <Box
        sx={styles.bottomButton({
          isBackShadow,
        })}
      >
        <Text sx={styles.bottomButtonLinkText} color={inBlog ? '#2D2828' : '#183F55'}>
          {text}
        </Text>
      </Box>
    </Link>
  );
};

export default WhiteButton;
