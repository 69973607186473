import { gql } from '@apollo/client';

import { FULL_BLOG_ARTICLE } from '../fragments';

export const GET_BLOG_ARTICLE = gql`
  ${FULL_BLOG_ARTICLE}
  query getBlogArticle($id: String!) {
    getBlogArticle(id: $id) {
      ...FullFragmentArticle
    }
  }
`;
