const MenuIcon = ({ iconColour }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 20'
    >
      <path
        fill={iconColour || '#fff'}
        fillRule='evenodd'
        d='M0 0h24v3.158H0V0zm0 8.421h24v3.158H0V8.42zm24 8.421H0V20h24v-3.158z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export default MenuIcon;
