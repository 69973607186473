export const styles = {
  blogPageWrapper: {
    display: 'flex',
    background: '#f1f1f1',
    flexDirection: 'column',
    minHeight: '100vh',
    paddingTop: {
      base: '8.533vw',
      md: '6.452vw',
      xl: '3.333vw',
    },
    paddingX: {
      base: '3.200vw',
      xl: '2.222vw',
    },
  },
  headerBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    width: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    height: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  navText: {
    fontWeight: 600,
    color: '#2D2828',
    fontFamily: 'Inter',
    fontSize: {
      base: '4vw',
      md: '2.016vw',
      xl: '1.042vw',
    },
    marginLeft: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  titleText: {
    fontFamily: 'Dela Gothic One',
    textTransform: 'uppercase',
    letterSpasing: '-0.015em',
    fontWeight: 400,
    fontSize: {
      base: '8vw',
      md: '7.527vw',
      xl: '3.889vw',
    },
    lineHeight: {
      base: '8.533vw',
      md: '8.333vw',
      xl: '4.306vw',
    },
    marginTop: {
      base: '3.200vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
  },
  subtitleText: {
    fontWeight: 400,
    fontFamily: 'Inter',
    color: '#000',
    marginTop: {
      base: '3.200vw',
      md: '2.151vw',
      xl: '1.667vw',
    },
    fontSize: {
      base: '4.267vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    lineHeight: {
      base: '5.333vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    marginBottom: {
      base: '0vw',
      xl: '0.833vw',
    },
  },
  blogCategoryContainer: {
    marginTop: {
      base: '9.600vw',
      md: '8.065vw',
      xl: '4.167vw',
    },
  },
  blogTitleText: {
    fontFamily: 'Dela Gothic One',
    dontWeight: 400,
    color: {
      base: '#2D2828',
      md: '#000',
    },
    fontSize: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    lineHeight: {
      base: '9.333vw',
      md: '4.704vw',
      xl: '2.431vw',
    },
    marginBottom: {
      base: '5.333vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
  },
  cardWrapper: {
    boxShadow: {
      base: 'box-shadow: 0 1.067vw 2.133vw rgba(0, 0, 0, 0.06)',
      md: 'box-shadow: 0 0.538vw 1.075vw rgba(0, 0, 0, 0.06)',
      xl: 'box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.06)',
    },
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: {
      base: '82.667vw',
      md: '57.796vw',
      xl: '29.861vw',
    },
    height: {
      base: '136vw',
      md: '66.935vw',
      xl: '34.583vw',
    },
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  cardImage: {
    objectFit: 'cover',
    cursor: 'pointer',
    height: {
      base: '64.800vw',
      md: '33.468vw',
      xl: '17.292vw',
    },
    width: 'auto',
  },
  cardTextContentWrapper: {
    marginTop: {
      base: '5.333vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    paddingRight: {
      base: '5.333vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    paddingLeft: {
      base: '5.333vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
  },
  cardTitle: {
    color: '#2D2828',
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: {
      base: '4.533vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    lineHeight: {
      base: '5.600vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
  },
  cardTextWrapper: {
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    marginTop: {
      base: '2.667vw',
      md: '1.344vw',
      xl: '0.694vw',
    },
    height: {
      base: '41vw',
      md: '18vw',
      xl: '8.2vw',
    },
  },
  cardText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: {
      base: '4vw',
      md: '2.04vw',
      xl: '1.042vw',
    },
    lineHeight: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  buttonWrapper: {
    display: 'flex',
    position: 'absolute',
    bottom: {
      base: '9.600vw',
      md: '4.973vw',
      xl: '2.569vw',
    },
    alignItems: 'flex-end',
    zIndex: 4,
  },
  readMoreText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    color: '#2D2828',
    cursor: 'pointer',
    fontSize: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
    lineHeight: {
      base: '5.600vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  gradient: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '25%',
    background:
      'linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 55%, rgba(255,255,255,0) 100%)',
    zIndex: 3,
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  bottomMargin: {
    marginTop: {
      base: '19.200vw',
      md: '10.753vw',
      xl: '8.333vw',
    },
  },
  loadingSpinner: {
    position: 'absolute',
    height: '15vw',
    width: '15vw',
    top: '40%',
    alignSelf: 'center',
  },
};
