const QuestionIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 12 12'
    >
      <circle cx='6' cy='6' r='4.75' stroke='#2D2828' strokeWidth='0.5'></circle>
      <path
        fill='#2D2828'
        d='M5.5 7.327v-.054c.004-.353.039-.633.105-.841.068-.209.165-.377.29-.506.125-.129.275-.246.452-.352.113-.072.215-.153.306-.242s.163-.19.216-.306a.908.908 0 00.08-.384.788.788 0 00-.12-.437.795.795 0 00-.318-.285.993.993 0 00-.855-.011.791.791 0 00-.33.284.967.967 0 00-.15.492h-.864c.014-.341.1-.63.259-.864.16-.237.37-.416.63-.537a2.06 2.06 0 01.873-.182c.348 0 .653.066.915.196.261.13.464.31.608.543.145.231.218.5.218.81 0 .212-.033.403-.1.573-.065.169-.16.32-.283.452a1.94 1.94 0 01-.438.352c-.16.1-.291.205-.392.313a.995.995 0 00-.216.383 2.01 2.01 0 00-.074.549v.054H5.5zm.429 1.727a.551.551 0 01-.4-.165.55.55 0 01-.168-.403c0-.156.056-.288.167-.398a.547.547 0 01.401-.168c.153 0 .286.056.398.168.113.11.17.242.17.398a.587.587 0 01-.284.491.542.542 0 01-.284.077z'
      ></path>
    </svg>
  );
};

export default QuestionIcon;
