import { styles } from './styles';

const ImageCard = ({ src, alt, width, height }) => {
  return (
    <div style={styles.imageWrapper(width, height)}>
      <img src={src} alt={alt} style={styles.image} />
    </div>
  );
};

export default ImageCard;
