export const styles = {
  imageWrapper: (width, height, mt, position) => ({
    maxWidth: '100%',
    objectFit: 'cover',
    position: position || 'unset',
    overflow: 'hidden',
    zIndex: -1,
    width,
    marginTop: mt || 'unset',
    height,
  }),
  image: {
    objectFit: 'cover',
    width: 'inherit',
    height: 'inherit',
    zIndex: 'inherit',
  },
};
