import { Box, Text } from '@chakra-ui/react';

import { styles } from './styles';

export const BottomCard = (card, index, activeIndex, lastIndex, onClick, size) => {
  return (
    <Box sx={styles.bottomCardContainer} onClick={() => onClick(index)}>
      <Box
        className={index === activeIndex ? 'bootomSwadowActive' : 'bootomSwadow'}
        sx={styles.bottomTextContaiter(index, lastIndex)}
      >
        <Text sx={styles.bottomSubTitleText}>{card.name}</Text>
      </Box>
      {index + 1 !== size ? <Box sx={styles.bottomDivider}></Box> : null}
    </Box>
  );
};
