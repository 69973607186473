import 'swiper/css';
import 'swiper/css/navigation';
import './css-module.css';

import { Box, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import WhiteButton from 'src/components/white-button';
import { useGetCompletedToursQuery } from 'src/hooks';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BottomCard } from './BottomCard';
import { Card } from './Card';
import { styles } from './styles';

export const MyTravels = () => {
  const sliderReference = React.useRef();
  const sliderReferenceBottom = React.useRef();
  const [activeIndex, setActiveIndex] = React.useState(0);

  const { data } = useGetCompletedToursQuery();

  const variant = useBreakpointValue({
    xl: {
      slidesPerView: 'auto',
      centeredSlides: false,
    },
    md: {
      slidesPerView: 5,
      centeredSlides: false,
    },
    base: {
      slidesPerView: 'auto',
      centeredSlides: true,
    },
  });

  const onClickBottomCard = (index) => setActiveIndex(index);

  React.useEffect(() => {
    sliderReference.current?.swiper?.slideTo(activeIndex);
    sliderReferenceBottom.current?.swiper?.slideTo(activeIndex);
  }, [activeIndex]);

  const lastIndex = Number.parseInt(data?.getTours?.completedTours, 10) - 1;

  return (
    <Box sx={styles.mainContainer} id={'my-travels'} name={'my-travels'}>
      <Box>
        <Swiper
          ref={sliderReference}
          navigation={true}
          modules={[Navigation]}
          id={'my-travels-slider'}
          className='mySwiper'
          onSlideChange={(event_) => setActiveIndex(event_.realIndex)}
        >
          {data?.getTours.completedTours.map((card, index) => (
            <SwiperSlide key={index}>
              <Card card={card} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box sx={styles.bottomSwiperContainer}>
        <Swiper
          slidesPerView={variant.slidesPerView}
          centeredSlides={variant.centeredSlides}
          ref={sliderReferenceBottom}
          id={'my-travels-slider-bottom'}
        >
          {data?.getTours.completedTours.map((card, index) => (
            <SwiperSlide key={index} id={'my-travels-slider'}>
              {BottomCard(
                card,
                index,
                activeIndex,
                lastIndex,
                onClickBottomCard,
                data?.getTours.completedTours?.length,
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box sx={styles.buttomContainer}>
        <WhiteButton
          text={'Читать в блоге'}
          /* eslint-disable-next-line no-underscore-dangle */
          link={`/trip/article/${data?.getTours.completedTours[activeIndex]._id}`}
        />
      </Box>
    </Box>
  );
};
