import { Box, Text, useBreakpointValue } from '@chakra-ui/react';
import ScreenBackground from 'src/components/screen-background';

import { styles } from './styles';

export const Card = ({ card }) => {
  const variant = useBreakpointValue({
    xl: '55.556vw',
    md: '139.707vw',
    base: '216.533vw',
  });
  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.cardTextContainer}>
        <Text sx={styles.titleText}>Мои путешествия</Text>
        <Text sx={styles.subTitleText}>{card?.name}</Text>
        <Text sx={styles.descriptionText}>{card?.description}</Text>
      </Box>
      <ScreenBackground
        src={card?.urlPhotos[0]}
        alt='background'
        width={'100%'}
        height={variant}
        position={'absolute'}
      />
    </Box>
  );
};
