const MaskIcon = ({ isActive }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 27 27'
    >
      <g filter='url(#filter0_i_37_187)'>
        <circle cx='13.5' cy='13.5' r='13.5' fill='#F1F1F1'></circle>
      </g>
      <path
        fill={isActive ? '#3C8BC7' : '#CDCDCD'}
        d='M8.314 4.42H7.183v5.336c.386-.14.744-.252 1.13-.365v-4.97zM14.271 20.09h-2.455a.338.338 0 00-.33.337v.253h-.028c-1.627 0-2.951-1.264-3.117-2.864a4.912 4.912 0 01-1.048-.787l-.11-.112v.59c0 2.358 1.903 4.324 4.247 4.324h.028v.253c0 .168.138.337.33.337h2.456c.165 0 .33-.14.33-.337v-1.657c0-.196-.137-.337-.303-.337z'
      ></path>
      <path
        fill={isActive ? '#3C8BC7' : '#CDCDCD'}
        d='M21.966 11.61c-.055-.73-.524-1.348-1.186-1.572a20.428 20.428 0 00-6.785-1.18c-2.261 0-4.55.394-6.785 1.18-.662.224-1.13.842-1.186 1.572-.083 1.32-.027 3.735 1.407 5.224.772.786 1.82 1.207 3.089 1.207h.386a1.618 1.618 0 001.241-.73c.166-.253.442-.618.8-.927a1.499 1.499 0 011.02-.393c.387 0 .718.14 1.021.393.359.31.635.674.8.927.303.421.745.702 1.241.73h.386c1.27 0 2.317-.421 3.09-1.207 1.489-1.489 1.572-3.904 1.461-5.224zm-4.523 5.785h-.331a1.112 1.112 0 01-.772-.45 5.709 5.709 0 00-.91-1.038 2.122 2.122 0 00-1.435-.534 2.27 2.27 0 00-1.434.534 5.709 5.709 0 00-.91 1.039c-.193.28-.47.421-.772.45h-.332c-1.103 0-1.985-.338-2.647-1.012-1.241-1.32-1.297-3.538-1.214-4.718.028-.449.331-.87.745-1.01 2.179-.759 4.385-1.152 6.564-1.152 2.18 0 4.413.393 6.565 1.152.44.14.717.533.744 1.01.083 1.208.028 3.399-1.24 4.718-.608.674-1.49 1.011-2.621 1.011z'
      ></path>
      <path
        fill={isActive ? '#3C8BC7' : '#CDCDCD'}
        d='M20.422 11.189c-2.124-.73-4.275-1.124-6.4-1.124-2.123 0-4.274.366-6.398 1.124a.61.61 0 00-.386.505c-.055.899-.083 3.09 1.075 4.297.552.561 1.297.842 2.262.842h.304a.469.469 0 00.358-.196c.22-.31.552-.759.993-1.124a2.644 2.644 0 011.793-.674c.662 0 1.296.253 1.793.674.44.393.772.843.993 1.124.082.112.22.196.358.196h.303c.966 0 1.71-.28 2.262-.842 1.159-1.208 1.131-3.398 1.076-4.297a.61.61 0 00-.386-.505z'
      ></path>
      <defs>
        <filter
          id='filter0_i_37_187'
          width='27'
          height='27'
          x='0'
          y='0'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'></feBlend>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation='3'></feGaussianBlur>
          <feComposite in2='hardAlpha' k2='-1' k3='1' operator='arithmetic'></feComposite>
          <feColorMatrix values='0 0 0 0 0.05 0 0 0 0 0.05 0 0 0 0 0.05 0 0 0 0.13 0'></feColorMatrix>
          <feBlend in2='shape' result='effect1_innerShadow_37_187'></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default MaskIcon;
