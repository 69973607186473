export const styles = {
  contactsScreenWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: {
      base: '3.200vw',
      md: '4.839vw',
      xl: '2.222vw',
    },
  },
  backgroundImage: {
    width: '100%',
    objectFit: 'cover',
    height: {
      base: '206.133vw',
      md: '87.533vw',
      xl: '34.167vw',
    },
    position: 'absolute',
    left: 0,
    zIndex: -1,
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: {
      base: '9.067vw',
      md: '6.452vw',
      xl: '3.889vw',
    },
  },
  titleText: {
    fontFamily: 'Dela Gothic One',
    letterSpacing: '-0.015em',
    textTransform: 'uppercase',
    fontWeight: 400,
    color: '#fff',
    fontSize: {
      base: '8vw',
      md: '7.527vw',
      xl: '3.889vw',
    },
    lineHeight: {
      base: '8.533vw',
      md: '8.333vw',
      xl: '4.306vw',
    },
    minWidth: {
      xl: '66.319vw',
    },
  },
  toHomeButton: {
    background: '#F1F1F1',
    borderRadius: 'full',
    cursor: 'default',
    height: {
      base: '23.467vw',
      md: '11.828vw',
      xl: '6.111vw',
    },
    width: {
      base: '23.467vw',
      md: '11.828vw',
      xl: '6.111vw',
    },
    marginLeft: {
      base: '35.200vw',
      md: '',
      xl: '17.847vw',
    },
    marginBottom: {
      base: '43.467vw',
      md: '10.753vw',
      xl: 'unset',
    },
    _hover: {
      transition: '0.3s',
      filter: {
        base: 'drop-shadow(0.800vw 0.800vw 7.467vw  rgba(255, 255, 255, 0.5))',
        md: 'drop-shadow(0.403vw 0.403vw 3.763vw rgba(255, 255, 255, 0.5))',
        xl: 'drop-shadow(0.208vw 0.208vw 1.944vw rgba(255, 255, 255, 0.5))',
      },
    },
  },
  upLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'inherit',
    height: 'inherit',
    borderRadius: 'inherit',
    zIndex: 1,
    cursor: 'pointer',
  },
  btnIconWrapper: {
    height: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    width: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
  },
  buttonText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    color: '#2D2828',
    lineHeight: '125%',
    fontSize: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
    marginTop: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#fff',
    lineHeight: '120%',
    fontSize: {
      base: '4.267vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    marginTop: {
      base: '3.200vw',
      md: '2.688vw',
      xl: '1.667vw',
    },
    maxWidth: {
      base: '83.200vw',
      md: '75.269vw',
      xl: '60vw',
    },
  },
  contactsGrid: {
    marginTop: {
      base: '21.333vw',
      md: '6.452vw',
      xl: '3.333vw',
    },
    marginBottom: {
      base: '33.067vw',
      md: '10.753vw',
      xl: '10vw',
    },
    maxWidth: {
      base: '83vw',
      md: '63vw',
      xl: '33vw',
    },
  },
  contactButton: {
    background: '#F1F1F1',
    height: {
      base: '12.800vw',
      md: '6.452vw',
      xl: '3.333vw',
    },
    width: {
      base: '38.400vw',
      md: '19.355vw',
      xl: '10vw',
    },
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  labelLink: {
    display: 'flex',
    width: 'inherit',
    height: 'inherit',
    borderRadius: 'inherit',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelIconWrapper: {
    width: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    height: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
  },
  labelText: {
    fontFamily: 'Inter',
    transition: '0.3s',
    fontWeight: 500,
    color: '#2D2828',
    fontSize: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
    marginLeft: {
      base: '1.067vw',
      md: '0.538vw',
      xl: '0.278vw',
    },
  },
};
