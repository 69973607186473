import { Box } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import {
  Blog,
  ContactsScreen,
  FreedivingAndMeScreen,
  HomeScreen,
  MyTravels,
  Review,
  Tours,
} from 'src/layouts';

import { styles } from './styles';

const Home = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.state) {
      scroller.scrollTo(location.state);
    }
  }, [location.state]);

  return (
    <Box sx={styles.homePageWrapper}>
      <HomeScreen />
      <FreedivingAndMeScreen />
      <MyTravels />
      <Tours />
      <Review />
      <Blog />
      <ContactsScreen />
    </Box>
  );
};

export default Home;
