export const styles = {
  tripArticlePageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f1f1f1',
    minHeight: '100vh',
  },
  headerBlock: {
    display: 'flex',
    zIndex: 2,
    paddingTop: {
      base: '8.533vw',
      md: '6.452vw',
      xl: '3.333vw',
    },
    paddingX: {
      base: '3.200vw',
      xl: '2.222vw',
    },
    position: 'absolute',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  navBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    width: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    height: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  navText: {
    fontWeight: 600,
    color: '#F1F1F1',
    fontFamily: 'Inter',
    fontSize: {
      base: '4vw',
      md: '2.016vw',
      xl: '1.042vw',
    },
    marginLeft: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  sliderContainer: {
    width: '100%',
    height: {
      base: '129.067vw',
      md: '65.188vw',
      xl: '55.556vw',
    },
    boxShadow: {
      base: '0 1.067vw 2.133vw rgba(0, 0, 0, 0.06)',
      md: 'unset',
    },
  },
  slideWrapper: {
    width: '100%',
    height: {
      base: '129.067vw',
      md: '65.188vw',
      xl: '55.556vw',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  slideImage: {
    width: 'inherit',
    height: 'inherit',
    objectFit: 'cover',
    overflow: 'hidden',
    borderRadius: 0,
  },
  slideTitle: {
    fontFamily: 'Dela Gothic One',
    textTransform: 'uppercase',
    fontWeight: 400,
    color: '#fff',
    letterSpacing: '-0.015em',
    lineHeight: '110%',
    fontSize: {
      base: '9.600vw',
      md: '7.527vw',
      xl: '3.889vw',
    },
    position: 'absolute',
    marginLeft: {
      base: '3.200vw',
      md: '3.226vw',
      xl: '2.222vw',
    },
    top: {
      base: '17.067vw',
      md: '12.366vw',
      xl: '6.389vw',
    },
  },
  imageDescription: {
    height: {
      md: '9.274vw',
      xl: '4.792vw',
    },
    width: {
      md: '57.930vw',
      xl: '29.931vw',
    },
    borderRadius: {
      md: '11.022vw',
      xl: '5.694vw',
    },
    background: '#F1F1F1',
    position: 'absolute',
    top: {
      md: '51.344vw',
      xl: '49.931vw',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    zIndex: 20,
  },
  imageDescriptionText: {
    color: '#2D2828',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '133%',
    textAlign: 'center',
    fontSize: {
      md: '2.016vw',
      xl: '1.042vw',
    },
    paddingX: {
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  mobileImageDescription: {
    width: '100%',
    height: '19.200vw',
    background: '#f1f1f1',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileImageDescriptionText: {
    color: '#183F55',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '107%',
    textAlign: 'center',
    fontSize: '4vw',
    paddingX: '15.600vw',
  },
  tripInfoContainer: {
    marginTop: {
      base: '9.600vw',
      md: '7.527vw',
      xl: '5.556vw',
    },
    marginLeft: {
      base: '3.200vw',
      md: '4.301vw',
      xl: '2.222vw',
    },
  },
  aboutTitle: {
    fontFamily: 'Dela Gothic One',
    color: '#2D2828',
    fontWeight: 400,
    fontSize: {
      base: '8vw',
      md: '5.914vw',
      xl: '3.056vw',
    },
    lineHeight: {
      md: '8.602vw',
      xl: '4.444vw',
    },
    letterSpacing: {
      base: '-0.015em',
      md: 'unset',
    },
    marginBottom: {
      base: '6.400vw',
      md: '6.452vw',
      xl: '3.333vw',
    },
  },
  labelsWrapper: {
    width: {
      base: '91.200vw',
      md: '85.484vw',
      xl: '66.944vw',
    },
  },
  labelItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    boxShadow: {
      base: '0 0.800vw 2.133vw rgba(0, 0, 0, 0.06)',
      md: '0 0.403vw 1.075vw rgba(0, 0, 0, 0.06)',
      xl: '0 0.208vw 0.556vw rgba(0, 0, 0, 0.06)',
    },
  },
  labelTextWrapper: {
    display: 'flex',
    paddingX: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
    paddingTop: {
      base: '2.133vw',
      md: '1.075vw',
      xl: '0.556vw',
    },
    paddingBottom: {
      base: '2.133vw',
      md: '1.075vw',
      xl: '0.556vw',
    },
  },
  labelKey: {
    color: '#2D2828',
    fontWeight: 600,
    fontFamily: 'Inter',
    lineHeight: '133%',
    fontSize: {
      base: '4vw',
      md: '2.016vw',
      xl: '1.042vw',
    },
    paddingRight: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  labelValue: {
    color: '#2D2828',
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '133%',
    fontSize: {
      base: '4vw',
      md: '2.016vw',
      xl: '1.042vw',
    },
  },
  additionalInfo: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
    marginTop: {
      base: '7vw',
      md: '5vw',
      xl: '2vw',
    },
    maxWidth: {
      md: '85.887vw',
      xl: '44.375vw',
    },
    color: '#2D2828',
  },
  daysContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  dayContentWrapper: {
    width: {
      base: '95.200vw',
      md: '85.887vw',
      xl: '44.375vw',
    },
    position: 'relative',
  },
  dot: {
    width: {
      base: '2.400vw',
      md: '1.210vw',
      xl: '0.625vw',
    },
    height: {
      base: '2.400vw',
      md: '1.210vw',
      xl: '0.625vw',
    },
    background: '#2D2828',
    borderRadius: 'full',
    marginTop: {
      base: '12.4vw',
      md: '6.5vw',
      xl: '3.2vw',
    },
    marginRight: {
      base: '3.200vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    zIndex: 1,
  },
  dayTitle: {
    fontFamily: 'Inter',
    fontWeight: 700,
    lineHeight: '100%',
    color: '#2D2828',
    fontSize: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    marginBottom: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    marginTop: {
      base: '10.667vw',
      md: '5.376vw',
      xl: '2.778vw',
    },
  },
  stack: {
    maxWidth: {
      base: '88.533vw',
      md: '79.704vw',
      xl: '41.181vw',
    },
  },
  line: {
    borderLeft: {
      base: '0.267vw dashed',
      md: '0.134vw dashed',
      xl: '0.069vw dashed',
    },
    height: '100%',
    width: {
      base: '0.267vw',
      md: '0.134vw',
      xl: '0.069vw',
    },
    position: 'absolute',
    top: {
      base: '12.5vw',
      md: '7vw',
      xl: '3.2vw',
    },
    left: {
      base: '-4.5vw',
      md: '-3.95vw',
      xl: '-1.995vw',
    },
    zIndex: 0,
  },
  modalBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(90deg, #44789F 0%, #58AD90 100%)',
    outline: 'none',
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    width: {
      base: '93.600vw',
      md: '36.156vw',
      xl: '18.681vw',
    },
    height: {
      base: '11.733vw',
      md: '5.914vw',
      xl: '3.056vw',
    },
    marginTop: {
      base: '16vw',
      md: '11.290vw',
      xl: '5.833vw',
    },
    marginBottom: {
      base: '19.200vw',
      md: '10.753vw',
      xl: '8.333vw',
    },
    _hover: {
      background: 'linear-gradient(90deg, #5994C1 0%, #6DD6B2 100%)',
    },
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
    color: '#fff',
  },
  modalWrapper: {
    background: '#F1F1F1',
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    minWidth: {
      base: '93.867vw',
      md: '72.581vw',
      xl: '35vw',
    },
    padding: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
  },
  modalCloseBtn: {
    alignSelf: 'flex-end',
    width: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    height: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    marginBottom: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
  },
  modalContent: {
    paddingX: {
      base: '1.067vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    paddingBottom: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
  },
  modalTitle: {
    color: '#2D2828',
    fontFamily: 'Dela Gothic One',
    fontWeight: 400,
    fontSize: {
      base: '6.400vw',
      md: '3.226vw',
      xl: '1.667vw',
    },
    maxWidth: {
      base: '60.800vw',
      md: 'unset',
    },
  },
  modalNumberContainer: {
    width: {
      base: '85.333vw',
      md: '61.290vw',
      xl: '31.667vw',
    },
    transition: '0.3s',
    height: {
      base: '21.333vw',
      md: '10.753vw',
      xl: '5.556vw',
    },
    background: '#fff',
    borderRadius: {
      base: '4.800vw',
      md: '2.419vw',
      xl: '1.250vw',
    },
    marginTop: {
      base: '7.467vw',
      md: '3.763vw',
      xl: '1.944vw',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: {
      base: '8vw',
      md: '5.376vw',
      xl: '2.778vw',
    },
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.06)',
    marginBottom: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    cursor: 'pointer',
  },
  contactLabel: {
    _visited: {
      textDecoration: 'none',
    },
    _active: {
      textDecoration: 'none',
    },
    _focus: {
      textDecoration: 'none',
    },
    width: {
      base: '85.333vw',
      md: '19.355vw',
      xl: '10vw',
    },
    height: {
      base: '12.800vw',
      md: '6.452vw',
      xl: '3.333vw',
    },
    borderRadius: {
      base: '3.200vw',
      md: '1.613vw',
      xl: '0.833vw',
    },
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.06)',
  },
  contactIconWrapper: {
    height: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
    width: {
      base: '5.333vw',
      md: '2.688vw',
      xl: '1.389vw',
    },
  },
  contactText: {
    fontFamily: 'Inter',
    textDecoration: 'none',
    focus: {
      textDecoration: 'none',
    },
    active: {
      textDecoration: 'none',
    },
    fontWeight: 500,
    color: '#2D2828',
    lineHeight: '125%',
    fontSize: {
      base: '4.267vw',
      md: '2.151vw',
      xl: '1.111vw',
    },
    marginLeft: {
      base: '1.067vw',
      md: '0.538vw',
      xl: '0.278vw',
    },
  },
  galleryModalWrapper: {
    width: {
      base: '100vw',
      md: '100vw',
      xl: '95vw',
    },
    height: {
      base: '66.133vw',
      md: '66.129vw',
      xl: '45vw',
    },
  },
  modalImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  loadingSpinner: {
    position: 'absolute',
    height: '15vw',
    width: '15vw',
    top: '40%',
    alignSelf: 'center',
  },
};
