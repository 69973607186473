import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './css-module.css';

import { Box, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import ScreenBackground from 'src/components/screen-background';
import { useGetReviewsQuery } from 'src/hooks';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import backgroundImage from '../../assets/images/review-background.png';
import { Card } from './Card';
import { styles } from './styles';

export const Review = () => {
  const rowsPerPage = 4;
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const variant = useBreakpointValue({
    xl: '54.556vw',
    md: '139.247vw',
    base: '226.533vw',
  });

  // eslint-disable-next-line no-unused-vars
  const { data, fetchMore, loading } = useGetReviewsQuery({
    variables: {
      limit: rowsPerPage,
      offset: 0,
    },
  });

  const onSlideChange = (swiper) => {
    if (
      swiper.activeIndex > currentIndex &&
      swiper.activeIndex * rowsPerPage < data?.getReviewsWithCount?.count &&
      !loading
    ) {
      fetchMore({
        variables: {
          limit: rowsPerPage,
          offset: rowsPerPage * swiper.activeIndex,
        },
      });
      setCurrentIndex(swiper.activeIndex);
    }
  };

  return (
    <Box sx={styles.mainContainer} id={'reviews'} name={'reviews'}>
      <ScreenBackground
        src={backgroundImage}
        alt='background'
        width={'100%'}
        height={variant}
        position={'absolute'}
      />
      <Box sx={styles.contentContainer}>
        <Text sx={styles.titleText}>ЧТО ГОВОРЯТ ТЕ, КТО УЖЕ ЕЗДИЛ</Text>
        <Swiper
          noSwiping={loading}
          observeParents={true}
          observer={true}
          rebuildOnUpdate
          shouldSwiperUpdate
          spaceBetween={20}
          navigation={true}
          pagination={true}
          modules={[Navigation, Pagination]}
          id={'my-review-slider'}
          className='mySwiper'
          onSlideChange={onSlideChange}
        >
          {data?.getReviewsWithCount?.reviews.map((card, index) => (
            <SwiperSlide key={index}>
              <Card card={card} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};
