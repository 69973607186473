import {
  Box,
  Heading,
  HStack,
  Image,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import MaskIcon from 'src/assets/icons/MaskIcon';
import QuestionIcon from 'src/assets/icons/QuestionIcon';

import { styles } from './styles';

export const CarouselCard = ({ tourInfo }) => {
  const content = [];

  // eslint-disable-next-line no-plusplus
  for (let index = 1; index < 6; index++) {
    content.push(
      <Box sx={styles.labelIconWrapper} key={index}>
        <MaskIcon isActive={tourInfo?.diveDifficultyCategory >= index} />
      </Box>,
    );
  }
  return (
    <Box sx={styles.cardWrapper}>
      <Link
        /* eslint-disable-next-line no-underscore-dangle */
        href={`/trip/article/${tourInfo._id}`}
        sx={{
          width: 'inherit',
          height: 'inherit',
        }}
      >
        <Image
          loading='lazy'
          src={tourInfo?.urlPhotos[0]}
          sx={styles.cardImage}
          alt={tourInfo?.photoName}
        />
      </Link>
      <Box sx={styles.difficultyLabel}>
        <Box sx={styles.labelInfo}>
          <Text sx={styles.labelText}>Категория сложности</Text>
          <Popover>
            <PopoverTrigger>
              <Box sx={styles.popoverIconWrapper}>
                <QuestionIcon />
              </Box>
            </PopoverTrigger>
            <Portal>
              <PopoverContent sx={styles.popoverContent}>
                <Heading sx={styles.tipTitle}>Категории сложности погружений</Heading>
                <Text sx={styles.tipContent}>
                  Если ты начинающий фридайвер выбери не сложные туры, их большинство. Для того,
                  чтоб плавать с дельфинами - достаточно базовых знаний о фридайвинге. Подходят даже
                  детям. Для тех, кто хочет прогресировать - выбирай туры с обучением фридайвингу,
                  мы индивидуально можем тренировать и тренироваться.
                </Text>
              </PopoverContent>
            </Portal>
          </Popover>
        </Box>
        <HStack sx={styles.difficultyIconsWrapper} spacing={'3px'}>
          {content}
        </HStack>
      </Box>
      <Heading sx={styles.cardTitle}>{tourInfo?.name}</Heading>
    </Box>
  );
};
