import { gql } from '@apollo/client';

export const GET_REVIEWS = gql`
  query getReviewsWithCount($limit: Int, $offset: Int) {
    getReviewsWithCount(limit: $limit, offset: $offset) {
      reviews {
        _id
        userFirstName
        userLastName
        userSocialNetwork
        text
        photoUrl
      }
      count
    }
  }
`;
